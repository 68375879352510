import { createAction } from 'redux-actions';

export const POLYGON_AREA = 'polygon/POLYGON_2D_AREA';
export const POLYGON_PERIMETER = 'polygon/POLYGON_2D_PERIMETER';
export const POLYGON_MIN_ELEVATION = 'polygon/POLYGON_MIN_ELEVATION';
export const POLYGON_MAX_ELEVATION = 'polygon/POLYGON_MAX_ELEVATION';
export const POLYGON_ELEVATION_DIFFERENCE = 'polygon/POLYGON_ELEVATION_DIFFERENCE';
export const POLYGON_BASE_SURFACE = 'polygon/POLYGON_BASE_SURFACE';
export const POLYGON_CUT_POLYGON = 'polygon/POLYGON_CUT_POLYGON';
export const POLYGON_CUT_ERROR = 'polygon/POLYGON_CUT_ERROR';
export const POLYGON_FILL_POLYGON = 'polygon/POLYGON_FILL_POLYGON';
export const POLYGON_FILL_ERROR = 'polygon/POLYGON_FILL_ERROR';
export const POLYGON_DIFFERENCE = 'polygon/POLYGON_DIFFERENCE';
export const CALCULATE_POLYGON = 'polygon/CALCULATE_POLYGON';
export const SLOPE_POLYGON = 'polygon/SLOPE_POLYGON';

export const polygonAction = {
    setPolygonArea:         createAction(POLYGON_AREA),
    setPolygonPerimeter:    createAction(POLYGON_PERIMETER),
    setPolygonMinElevation:   createAction(POLYGON_MIN_ELEVATION),
    setPolygonMaxElevation:   createAction(POLYGON_MAX_ELEVATION),
    setPolygonElevationDifference: createAction(POLYGON_ELEVATION_DIFFERENCE),
    setPolygonBaseSurface:    createAction(POLYGON_BASE_SURFACE),
    setPolygonCutPolygon:      createAction(POLYGON_CUT_POLYGON),
    setPolygonCutError:       createAction(POLYGON_CUT_ERROR),
    setPolygonFillPolygon:     createAction(POLYGON_FILL_POLYGON),
    setPolygonFillError:      createAction(POLYGON_FILL_ERROR),
    setPolygonDifference: createAction(POLYGON_DIFFERENCE),
    calculatePolygon: createAction(CALCULATE_POLYGON),
    setSlope: createAction(SLOPE_POLYGON),
};

import { initialState } from './selectors';
import {
	SELECT_PROJECT, ALL_DATASETS, ALL_PROJECTS, SET_MATERIAL, SET_POINTSIZE, SET_DATASET, SET_SORT_ORDER, SET_SORT_TYPE, ALL_ASSETS, SET_LABEL_PROPERTY, SET_ADDING_POSITION, ANALYTICS_INFO, LATEST_ANALYTICS, SET_DEFAULT_CLASSIFICATION
} from './actions';

export default function global(state = initialState, action = {}) {
	const { type, payload } = action
	switch (type) {
		case SELECT_PROJECT:
			return {
				...state,
				selectedProject: payload
			}
		case ALL_PROJECTS:
			return {
				...state,
				allProjects: payload
			}
		case ALL_DATASETS:
			return {
				...state,
				allDatasets: payload
			}
		case ALL_ASSETS:
			return {
				...state,
				allAssets: payload
			}
		case SET_MATERIAL:
			return {
				...state,
				materialMode: payload
			}
		case SET_POINTSIZE:
			return {
				...state,
				pointSize: payload
			}
		case SET_DATASET:
			return {
				...state,
				datasetList: payload
			}
		case SET_SORT_TYPE:
			return {
				...state,
				sortType: payload
			}
		case SET_SORT_ORDER:
			return {
				...state,
				sortOrder: payload
			}
		case SET_LABEL_PROPERTY:
			return {
				...state,
				labelProperty: payload
			}
		case SET_ADDING_POSITION:
			return {
				...state,
				addingPosition: payload
			}
		case ANALYTICS_INFO:
			return {
				...state,
				analyticsInfo: payload
			}
		case LATEST_ANALYTICS:
			return {
				...state,
				isLatestAnalytics: payload
			}
		case SET_DEFAULT_CLASSIFICATION:
			return {
				...state,
				classificationList: payload
			}
		default:
			return state;
	}
}

import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { authActionCreators } from '../../store/actions';
import { makeStyles } from '@material-ui/core/styles';
import Header from '../../components/Header';
import './style.scss'
import * as api from '../../utils/api';
import { globalAction } from '../../store/actions';
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';
import ProgressBar from 'react-bootstrap/ProgressBar'
import * as mapUtils from '../../components/MapView/MapUtils';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

const useStyles = makeStyles(theme => ({
	button: {
    fontSize: 15,
    fontWeight: 400,
    marginTop: 20
  },
}));

function DownloadLayout(props) {
  const classes = useStyles();

  const [datasetItem, setDatasetItem] = React.useState(null);
  const [downloading, setDownloading] = React.useState(0); // 0 : initial, 1: downloading, 2: downloaded, 3: failed
  const [downloadProgress, setDownloadProgress] = React.useState(0);
  const [progressLabel, setProgressLabel] = React.useState("");
  const [downloadFileName, setDownloadFilename] = React.useState("");

  let dataset_id;

	if (api.getParameterByName("dataset_id") && api.getParameterByName("dataset_id") !== "") {
		dataset_id = api.getParameterByName("dataset_id");
	}

  if (!dataset_id || dataset_id === "") {
    window.close();
  }

  useEffect(() => {
    let userToken = localStorage.getItem("userToken");
    if (userToken) {
      if (props.userInfo.first_name !== "") {
        if (!api.canEditItem(props.userInfo)) {
          window.close();
        }
        else {
          getDatasetInfo();
        }
      }
    }
    else {
      window.close();
    }
	}, [props.userInfo]);

  async function getDatasetInfo() {
    let userToken = localStorage.getItem("userToken");
    let response = await api.getDatasetByID({token: userToken, id: dataset_id});

    if (response.data && !response.data.error && response.data.dataset) {
      setDatasetItem(response.data.dataset);
    }
    else {
      window.close();
    }
  }

  async function downloadDataset() {
    let userToken = localStorage.getItem("userToken");
    if (userToken) {
        let response = await api.getFilesByDatasetID({
            dataset_id: dataset_id,
            token: userToken});
        if (response.data && !response.data.error) {
            let files = response.data;
            if (!files || files.length === 0) {
                toast("Empty Dataset");
            }
            else {
              setDownloading(1);
              setDownloadProgress(0);
              downloadFiles(new JSZip(), files, 0);
            }
        }
        else {
            window.close();
        }
    }
    else {
        window.close();
    }
  }

  function downloadFiles(jsZip, files, index) {
    if (index === files.length) {
      jsZip.generateAsync({ type: "blob" }).then(function (blob) {
        saveAs(blob, datasetItem.name + '.zip');
      });

      setDownloading(2);
      return;
    }

    const url = encodeURI(mapUtils.getSubPath(datasetItem) + "/datasets/"+ datasetItem.id + "/" + files[index].name);
    setDownloadFilename(files[index].name);
    let xhr = new XMLHttpRequest();
    xhr.onprogress = function(evt) {
      if (evt.lengthComputable) {
        let percentage = parseInt(index * 100 / files.length + 100 * evt.loaded / evt.total / files.length)
        setDownloadProgress(percentage);
        setProgressLabel(percentage + "%");
      }
    };
    xhr.open('GET', url, true);
    xhr.responseType = "blob";
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        blobToBase64(xhr.response, function(binaryData){
            var fileName = files[index].name;
            jsZip.file(fileName, binaryData, {base64: true});
            downloadFiles(jsZip, files, index + 1);
        });
      }
    }
    xhr.send();
  }

  function blobToBase64(blob, callback) {
    var reader = new FileReader();
    reader.onload = function() {
        var dataUrl = reader.result;
        var base64 = dataUrl.split(',')[1];
        callback(base64);
    };
    reader.readAsDataURL(blob);
  }

  function calculateAndUpdateProgress(evt) {
      
  }

  function gotoLogin() {
    props.history.replace("/");
  }

  return (
    <div>
      <Header
				hasSearch={false}
			/>
      <div>
        {datasetItem?
        <div className='download_layout'>
          <div className='download_label'>
            Download Dataset "{datasetItem.name}"
          </div>
          {downloading !== 0?
          <div className="download-progress-contaner">
            <ProgressBar now={downloadProgress} label={progressLabel}/>
            {downloading === 1?
            <div className='download-file-name'>
              {downloadFileName}
            </div>
            :downloading === 2?
            <div className='download-status'>
              Download Completed!
            </div>
            :
            <div className='download-status'>
              Download Failed! Please retry to reload.
            </div>
            }
            
          </div>
          :
          <Button
            variant="contained"
            color="primary"
            onClick={downloadDataset}
            className={classes.button}
          >
            Download
          </Button>
          }
        </div>
        :null}
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  userInfo: state.global.userInfo 
});

DownloadLayout.propTypes = {
}

export default compose(
  withRouter,
  connect(mapStateToProps, authActionCreators),
  connect(mapStateToProps, globalAction),
)(DownloadLayout);
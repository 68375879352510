import React, {useEffect, useRef} from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar'
import { makeStyles } from '@material-ui/core/styles';
import { useDropzone } from 'react-dropzone';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { authActionCreators } from '../../store/actions';
import * as api from '../../utils/api';
import Creatable from 'react-select/creatable';
import Async from 'react-select/async';
import { promisify } from '../../utils/promisify';
import _, { isNull } from "lodash";

import './style.scss';
import { globalAction } from '../../store/global/actions';
import DatePicker from "react-datepicker";
import epsgJson from "../../data/epsg.json";

import "react-datepicker/dist/react-datepicker.css";
import Button from '@material-ui/core/Button';
import { DataGrid } from '@mui/x-data-grid';
import { toast } from 'react-toastify';
import { Checkbox} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import * as mapUtils from '../../components/MapView/MapUtils';

const CustomCheckbox = withStyles({
	root: {
		color: '#67757c',
		'&$checked': {
			color: '#67757c',
		},
	}
})(props => <Checkbox color="default" {...props} />);

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  button: {
    fontSize: 15,
    fontWeight: 400,
    marginTop: 10
  },
  backButton: {
    backgroundColor: `#cccccc !important`,
    marginRight: theme.spacing(1),
    fontSize: 15,
    width: 120,
    fontWeight: 400,
    float: `left`,
    marginLeft: '17.5%',//`calc(17% - 60px)`,
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  displayBlock: {
    fontSize: 13,
  },
}));

const selectStyle = {
  control: (base, state) => ({
    ...base,
    background: "#17181b",
    // match with the menu
    borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    // Overwrittes the different states of border
    borderColor: "#323232",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: "white"
    },
    color: 'white',
    textAlign: 'left',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'white'
  }),
  input: base => ({
    ...base,
    color: "white"
  }),
  option: (styles, {isFocused, isSelected}) => ({
    ...styles,
    background: isFocused?"#27282b":"#17181b",
    color : "white",
    zIndex: 1,
    textAlign: 'left',
  }),
  menu: base => ({
    ...base,
    // override border radius to match the box
    borderRadius: 0,
    // kill the gap
    marginTop: 0
  }),
  menuList: base => ({
    ...base,
    // kill the white space on first and last option
    padding: 0
  })
};

function TabPanel(props) {
	const { children, value, index, ...other } = props;
  
	return (
	  <div
		role="tabpanel"
		hidden={value !== index}
		id={`simple-tabpanel-${index}`}
		aria-labelledby={`simple-tab-${index}`}
		{...other}
	  >
		{value === index && (
		  <Box>
			{children}
		  </Box>
		)}
	  </div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
	return {
	  id: `simple-tab-${index}`,
	  'aria-controls': `simple-tabpanel-${index}`,
	};
}

function HorizontalStepper(props) {
  const classes = useStyles();
  const [dataSetObject, setDataSetObject] = React.useState({id: -1});
  const [progressing, setProgressing] = React.useState(false);
  const [uploadProgress, setUploadProgress] = React.useState(0);
  const [progressLabel, setProgressLabel] = React.useState("");
  const [uploading, setUploading] = React.useState(false);
  const [uploaded, setUploaded] = React.useState(false);
  const [statusMessage, setStatusMessage] = React.useState("");
  const [dataSetList, setDataSetList] = React.useState([]);
  const [files, setFiles] = React.useState([]);
  const [tableRows, setTableRows] = React.useState([]);

  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [supplier_name, setSupplierName] = React.useState("");
  const [capture_date, setCaptureDate] = React.useState(new Date());
  const [source, setSource] = React.useState("");
  const [elevation, setElevation] = React.useState("");
  const [elevationUnit, setElevationUnit] = React.useState("");
  const [merge, setMerge] = React.useState(true);
  const [compress, setCompress] = React.useState(true);
  const [lidarSubsample, setLidarSubsample] = React.useState(true);
  const [selectedDatasetID, setSelectedDatasetID] = React.useState(null);
  const [isFileAttach, setAttach] = React.useState(false);
  const [updatingDataset, setUpdatingDataset] = React.useState(false);

  const [tabValue, setTabValue] = React.useState(0); // 0: Uploading, 1: Existing
  const [currentFiles, setCurrentFiles] = React.useState([]);

  const [allFileSize, setAllFileSize] = React.useState(0);

  const inputFileRef = useRef(null);

  const elevationUnitList = [
    {
      label : "Meter",
      value : "meter"
    },
    {
      label : "Feet",
      value : "feet"
    }
  ];

  const [epsg_code, setEpsgCode] = React.useState("");
  const [epsgList, setEpsgList] = React.useState([]);

  const columns = [
    { field: 'name', headerName: 'Name', width: 250, sortable: false, headerClassName: 'file_table_class'},
    { field: 'capture_date', headerName: 'Capture Date', width: 150, sortable: false, headerClassName: 'file_table_class' },
    { field: 'filesize', headerName: 'FileSize', width: 100, sortable: false, headerClassName: 'file_table_class' },
    { field: 'status', headerName: 'Status', width: 150, sortable: false, headerClassName: 'file_table_class',
      renderCell: (cellValue) => {
        return (
          (uploading)?
          <div className="upload-progress-contaner">
            <ProgressBar now={cellValue.row.progress}/>
          </div>
          :
          <div className="table_icon" onClick={deleteFile}>
            <div className='icon delete_menu_icon hover_icon' data-id={cellValue.row.id}></div>
          </div>
        );
      }},
  ];

  const existColumns = [
    { field: 'name', headerName: 'Name', width: 250, sortable: false, headerClassName: 'file_table_class'},
    { field: 'upload_date', headerName: 'Upload Date', width: 150, sortable: false, headerClassName: 'file_table_class' },
    { field: 'action', headerName: 'Action', width: 150, sortable: false, headerClassName: 'file_table_class',
      renderCell: (cellValue) => {
        return (
          <div>
            <div className="table_exist_icon" onClick={downloadExistFile}>
              <div className='icon download_menu_icon hover_icon' data-id={cellValue.row.name}></div>
            </div>
            <div className="table_exist_icon" onClick={deleteExistFile}>
              <div className='icon delete_menu_icon hover_icon' data-id={cellValue.row.file_id}></div>
            </div>
          </div>
        );
      }},
  ];

  useEffect(() => {
    if (api.getParameterByName("dataset_id") && api.getParameterByName("dataset_id") != "") {
      getDatasetInfoAndSelect(api.getParameterByName("dataset_id"));
    }
    if (api.getParameterByName("attach") && api.getParameterByName("attach") === "true") {
      setAttach(true);
    }
    getDatasetList();
    generateEpsgJson();
    return () => {
    }
  }, []);

  function generateEpsgJson() {
    let optionValues = [];
    for (let i = 0; i < epsgJson.length; i ++) {
      optionValues.push({
        label : epsgJson[i].auth + ":" + epsgJson[i].srid,
        value : epsgJson[i].auth + ":" + epsgJson[i].srid
      });
    }
    setEpsgList(optionValues);
  }

  async function getDatasetInfoAndSelect(datasetID) {
    let userToken = localStorage.getItem("userToken");
    if (userToken) {
      setProgressStatus(true, 50);

      let response = await api.getDatasetByID({token: userToken, id: datasetID});
      setProgressStatus(false, 100);

      if (response.data && !response.data.error) {
        setDataSetUpdate(response.data.dataset, true);
        setUpdatingDataset(true);
      }
      else {
        if (response.data && response.data.error) {
          toast(response.data.error);
        }
        else {
          redirectToLogin();
        }
        
      }
    }
    else {
      redirectToLogin();
    }
  }

  async function getDatasetList() {
    let userToken = localStorage.getItem("userToken");
    if (userToken) {
      setProgressStatus(true, 50);

      let response = await api.getDatasetList({token: userToken});
      setProgressStatus(false, 100);

      if (response.data && !response.data.error) {
        setDataSetListOptions(response.data);
      }
      else {
        redirectToLogin();
      }
    }
    else {
      redirectToLogin();
    }
  }

  function setDataSetListOptions(datasets) {
    let optionValues = [];
    for (let i = 0; i < datasets.length; i ++) {
      optionValues.push({
        label: datasets[i].name,
        value: datasets[i]
      })
    }
    setDataSetList(optionValues);
  }

  function setProgressStatus(isProgressing, percentage, label) {
    setUploadProgress(percentage);
    setProgressing(isProgressing);
    if (label === undefined) {
      setProgressLabel("")
    }
    else {
      setProgressLabel(label);
    }
  }

  function redirectToLogin() {
    props.history.replace("/");
  }

  async function createDataset(name) {
    let userToken = localStorage.getItem("userToken");
    if (!userToken) {
      redirectToLogin();
      return;
    }
    let response;
    setProgressStatus(true, 50);
    response = await api.createDataset({token : userToken, name : name});
    setProgressStatus(false, 100);
    if (response.data && !response.data.error) {
      const listOfDataset = [{
        label: name,
        value: response.data
      }].concat(dataSetList);
      setDataSetList(listOfDataset);
      setDataSetUpdate(response.data);
      return;
    }
    else {
      redirectToLogin();
    }

    return false;
  }

  async function updateDataset(status, delete_data, callback, errorCallback) {
    let userToken = localStorage.getItem("userToken");
    if (!userToken) {
      redirectToLogin();
      return;
    }
    let response;
    setProgressStatus(true, 0);
    response = await api.updateDataset({
      token : userToken, name : name, id : dataSetObject.id, description : description, supplier_name : supplier_name, capture_date: capture_date.getTime() / 1000,
      source : source, epsg_code : epsg_code, status : status, delete_data: delete_data, elevation: elevation,
      elevation_unit: elevationUnit, merge: merge, compress: compress, lidar_subsample: lidarSubsample, file_size: allFileSize
    });
    setProgressStatus(false, 100);
    if (response.data && !response.data.error) {
      if (response.data.fail_message) {
        errorCallback(response.data.fail_message);
      }
      else if (callback)
        callback(response.data);
    }
    else {
      if (errorCallback) {
        errorCallback(response.data.error?response.data.error : "Network Error");
      }
    }

    return false;
  }

  function setProgressStatusByIndex(index, progress) {
    const rows = [...tableRows];
    rows[index].progress = progress;
    setTimeout(function() {
      setTableRows(rows);
    })
  }

  function uploadFileByAPI(index, callback) {
    let userToken = localStorage.getItem("userToken");
    if (!userToken) {
      redirectToLogin();
      return;
    }

    if (index === files.length) {
      callback();
      return;
    }

    let willUpload = { id : dataSetObject.id, files: [files[index]], token: userToken, attach: isFileAttach?true:false, onProgress: function(progressEvent) {
      let progressingStatus = Math.floor(progressEvent.loaded / progressEvent.total * 100);
      setProgressStatusByIndex(index, progressingStatus);
      let allProgress = Math.floor(100 * index / files.length + progressingStatus / files.length)
      setProgressStatus(true, allProgress, allProgress + "%")
    } };
    promisify (props.uploadFile, willUpload)
    .then ((res) => {
      if (!res.error) {
        setDataSetUpdate(res);
        uploadFileByAPI(index + 1, callback);
      }
      else {
        setStatusMessage(res.error + " Retrying.");
        uploadFileByAPI(index);
      }
    })
    .catch((err) => {
      console.log (err);
      uploadFileByAPI(index, callback);
    })
  }

  async function startProcessing() {
    let userToken = localStorage.getItem("userToken");
    if (!userToken) {
      redirectToLogin();
      return;
    }
    let response;
    setProgressStatus(true, 50);
    response = await api.startProcessDataset({
      token : userToken, id : dataSetObject.id, attach: (isFileAttach)?true:false
    });
    setProgressStatus(false, 100);
    if (response.data && !response.data.error) {
      setStatusMessage("Started process.");
      initProcess();
      getDatasetList() 
    }
    else {
      setStatusMessage((response.data.error)?response.data.error:"Start Process Failed. Please Retry Upload.");
    }

    return false;
  }

  function initProcess() {
    api.setParameter("dataset_id", "");
    api.setParameter("attach", "false");
    setFiles([]);
    setSelectedDatasetID(null);
    setUpdatingDataset(false);
    setCurrentFiles([]);
    setDataSetObject({id: -1});
    setName("");
    setDescription("");
    setSupplierName("");
    setCaptureDate(new Date());
    setSource("");
    setEpsgCode("");
    setElevationUnit("meter");
    setMerge(true);
    setCompress(true);
    setLidarSubsample(true);
    setElevation("");
    setStatusMessage("");
    setUploading(false);
    setUploaded(false);
    getDatasetList();
    setElevation("");
  }

  function selectDataset(newValue, actionMeta) {
    if (uploading) return;
    if (actionMeta.action === 'create-option') {
        createDataset(newValue.label);
    }
    else if (newValue) {
      if (newValue.value) {
        setDataSetUpdate(newValue.value, true);
      }
    }
  }

  async function getCurrentFilesList(datasetID) {
    if (datasetID) {
      let userToken = localStorage.getItem("userToken");
      if (userToken) {
        let response = await api.getFilesByDatasetID({
            dataset_id: datasetID,
            token: userToken});
        if (response.data && !response.data.error) {
            if (response.data.length > 0) {
              let rows = [];
              for (let i = 0; i < response.data.length; i ++) {
                rows.push({
                  id : i,
                  file_id: response.data[i].id,
                  name: response.data[i].name,
                  upload_date : new Date(response.data[i].updated_at).toISOString().split('T')[0],
                });
              }
              setTimeout(function() {
                setCurrentFiles(rows);
              })
            }
        }
        else {
            toast("Failed getting files. Please Retry to relogin.");
        }
      }
    }
  }

  function setDataSetUpdate(object, check_option) {
    setSelectedDatasetID(object.id);
    setCurrentFiles([]);
    getCurrentFilesList(object.id);
    if (object.name) {
      setName(object.name);
    }
    else {
      setName("");
    }
    if (object.description) {
      setDescription(object.description);
    }
    else {
      setDescription("");
    }

    if (object.supplier_name) {
      setSupplierName(object.supplier_name);
    }
    else {
      setSupplierName("");
    }
    
    if (object.elevation) {
      setElevation(object.elevation);
    }
    else {
      setElevation("");
    }

    if (object.elevation_unit) {
      setElevationUnit(object.elevation_unit);
    }
    else {
      setElevationUnit("meter");
    }

    if (check_option) {
      setMerge(true);
      setCompress(true);
      setLidarSubsample(true);
    }
    else {
      if (object.merge !== undefined) {
        setMerge((object.merge === 1));
      }
      else {
        setMerge(true);
      }
  
      if (object.compress !== undefined) {
        setCompress((object.compress === 1));
      }
      else {
        setCompress(true);
      }

      if (object.lidar_subsample !== undefined) {
        setLidarSubsample((object.lidar_subsample === 1));
      }
      else {
        setLidarSubsample(true);
      }
    }

    if (object.source) {
      setSource(object.source);
    }
    else {
      setSource("");
    }

    if (object.epsg_code) {
      setEpsgCode(object.epsg_code);
    }
    else {
      setEpsgCode("");
    }
    if (object.capture_date) {
      setCaptureDate(new Date(object.capture_date));
    }
    else {
      setCaptureDate(new Date());
    }

    setDataSetObject(object);
  }

  function generateFileRows(acceptedFiles) {
    let rows = [];
    let sumFileSize = 0;
    for (let i = 0; i < acceptedFiles.length; i ++) {
      rows.push({
        id : i,
        name: acceptedFiles[i].name,
        capture_date : new Date(acceptedFiles[i].lastModified).toISOString().split('T')[0],
        filesize: humanFileSize(acceptedFiles[i].size),
        status : i,
        progress : 0
      });
      sumFileSize += acceptedFiles[i].size;
    }
    setAllFileSize(sumFileSize);
    setTimeout(function() {
      setTableRows(rows);
    })
  }

  function humanFileSize(bytes, si=true, dp=1) {
    const thresh = si ? 1000 : 1024;
  
    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }
  
    const units = si 
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10**dp;
  
    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
  
    return bytes.toFixed(dp) + ' ' + units[u];
  }

  function downloadExistFile(event) {
    if (event.target && event.target.dataset.id) {
      let fileName = event.target.dataset.id;
      fetch(encodeURI(mapUtils.getSubPath(dataSetObject) + "/datasets/"+ dataSetObject.id + "/" + fileName), {cache: "no-store"}).then(response => {
          response.blob().then(blob => {
              // Creating new object of PDF file
              const fileURL = window.URL.createObjectURL(blob);
              // Setting various property values
              let alink = document.createElement('a');
              alink.href = fileURL;
              alink.download = fileName;
              alink.click();
          })
      })
    }
  }

  async function deleteExistFile(event) {
    if (event.target && event.target.dataset.id) {
      let userToken = localStorage.getItem("userToken");
      if (userToken) {
        setProgressStatus(true, 50);

        let response = await api.deleteExistFile({token: userToken, id: event.target.dataset.id, dataset_id: dataSetObject.id});
        setProgressStatus(false, 100);

        if (response.data && response.data.success) {
          toast(response.data);
          getCurrentFilesList(dataSetObject.id);
        }
        else {
          if (response.data && response.data.error) {
            toast(response.data.error);
          }
          else {
            redirectToLogin();
          }
        }
      }
      else {
        redirectToLogin();
      }
    }
  }

  function deleteFile(event) {
    if (event.target && event.target.dataset.id) {
      let acceptedFiles = files;
      acceptedFiles.splice(event.target.dataset.id, 1);
      if (acceptedFiles.length > 0) {
        setCaptureDate(new Date(acceptedFiles[0].lastModified));
      }
      setFiles(acceptedFiles);
      generateFileRows(acceptedFiles);
    }
  }

  function InfoUpdateRenderer() {
    function onChangeName(evt) {
      setName(evt.target.value);
    }

    function onChangeDescription(evt) {
      setDescription(evt.target.value);
    }

    function onChangeSupplierName(evt) {
      setSupplierName(evt.target.value);
    }

    function onChangeElevation(evt) {
      setElevation(evt.target.value);
    }

    function selectCRS(newValue, actionValue) {
      setEpsgCode(newValue.label);
    }

    function selectElevationUnit(newValue, actionValue) {
      setElevationUnit(newValue.value);
    }

    function handleFileAttach() {
      if (isFileAttach) {
        api.setParameter("attach", "");
      }
      else {
        api.setParameter("attach", "true");
      }
      setAttach(!isFileAttach)
    }

    function handleMerge() {
      setMerge(!merge)
    }

    function handleCompress() {
      setCompress(!compress)
    }

    function handleLidarSubsample() {
      setLidarSubsample(!lidarSubsample)
    }

    function loadOption(inputValue) {
      return new Promise((resolve, reject) => {
        const filtered = _.filter(epsgList, o =>
          _.includes(_.toLower(o.label), _.toLower(inputValue))
        );
        resolve(filtered.slice(0, 10));
      });
    }

    function loadElevationOption(inputValue) {
      return new Promise((resolve, reject) => {
        const filtered = _.filter(elevationUnitList, o =>
          _.includes(_.toLower(o.label), _.toLower(inputValue))
        );
        resolve(elevationUnitList.slice(0, 10));
      });
    }

    function uploadFiles() {
      setStatusMessage("Uploading");
      setUploading(true);
      setUploaded(false);
      if (files.length !== 0) {
        setProgressStatus(true, 0);
        uploadFileByAPI(0, function() {
          setStatusMessage("Upload Done");
          setProgressStatus(false, 100);
          updateDataset("uploaded", false, function(data) {
            setDataSetUpdate(data);
            startProcessing();
            setUploading(false);
            setUploaded(true);
          }, function(error) {
            setStatusMessage(error);
          });
        });
      }
    }

    function updateAndUpload() {
      if (dataSetObject.status === "uploading" || dataSetObject.status === "processing") {
        toast("Dataset is processing. So please wait for status change.");
        return;
      }
      if (uploading || uploaded) {
        updateDataset(dataSetObject.status, false, function(data) {
          setDataSetUpdate(data);
          if (uploaded && data.status !== "processing") {
            startProcessing();
          }
        }, function(error) {
          setStatusMessage(error);
        });
      }
      else {
        updateDataset("uploading", (isFileAttach)?false:true, function(data) {
          setDataSetUpdate(data);
          uploadFiles();
        }, function(error) {
          setStatusMessage(error);
        });
      }
    }

    function updateInfo() {
      if (dataSetObject.status === "uploading" || dataSetObject.status === "processing") {
        toast("Dataset is processing. So please wait for status change.");
        return;
      }
      updateDataset(dataSetObject.status, false, function(data) {
        setDataSetUpdate(data);
        getDatasetList();
      }, function(error) {
        setStatusMessage(error);
      });
    }

    function getLabel() {
      if (uploaded) {
        return "Update & Process"
      }
      else if (uploading) {
        return "Update";
      }
      else {
        return "Update & Upload"
      }
    }

    function getFileGridClassName(param) {
      return "file_table_class";
    }

    function addFiles() {
      inputFileRef.current.click();
    }

    function handleFileChange(event) {
      let acceptedFiles = files;
      if (event.target.files.length > 0) {
        for (let i = 0; i < event.target.files.length; i ++) {
          acceptedFiles.push(event.target.files[i]);
        }
        if (acceptedFiles.length > 0) {
          setCaptureDate(new Date(acceptedFiles[0].lastModified));
        }
        setFiles(acceptedFiles);
        generateFileRows(acceptedFiles);
      }
    }

    const handleTabChange = (event, newValue) => {
      setTabValue(newValue);
    };

    const { getRootProps, getFileInputProps } = useDropzone({
      onDrop: acceptedFiles => {
        let currentFiles = files;
        if (acceptedFiles.length > 0) {
          for (let i = 0; i < acceptedFiles.length; i ++) {
            currentFiles.push(acceptedFiles[i]);
          }
          if (currentFiles.length > 0) {
            setCaptureDate(new Date(currentFiles[0].lastModified));
          }
          setFiles(currentFiles);
          generateFileRows(currentFiles);
        }
      }
    });

    return (
      <div>
      {(files.length !== 0 || selectedDatasetID !== null) ?
        <div className='info-upload'>
          <div className='dataset-info-box'>
            {!updatingDataset ?
            <div className='project-name-box'>
              <p className="project-name-title">Dataset Name</p>
              <div className="datasets_selection">
              <Creatable
                isClearable
                isMulti={false}
                onChange={selectDataset}
                styles={selectStyle}
                options={dataSetList}
                isDisabled={uploading}
                placeholder={'Select existing or create new'}
                value = {
                  dataSetList.filter(option => 
                    option.value.id === dataSetObject.id)
                }
              ></Creatable>
              </div>
            </div>
            :
            <div className='project-name-box'>
              <p className="project-name-title">Name</p>
              <input type="text" className="project-name-input" 
                value={name} 
                onChange={onChangeName}></input>
            </div>
            }
            
            <div className='project-name-box'>
              <p className="project-name-title">Capture Date</p>
              <DatePicker selected={capture_date} onChange={(date) => setCaptureDate(date)} />
            </div>
            <div className='project-name-box'>
              <p className="project-name-title">Description</p>
              <textarea type="textarea" className="project-name-description" 
                value={description} 
                onChange={onChangeDescription}></textarea>
            </div>
            <div className='project-name-box'>
              <p className="project-name-title">Supplier Name</p>
              <input type="text" className="project-name-input" 
                value={supplier_name} 
                onChange={onChangeSupplierName}></input>
            </div>
            <div className='project-name-box project-flex-column'>
              <div className='project-half-data'>
                <p className="project-name-title">Elevation Units(ft, m)</p>
                <div style={{paddingTop: 10}}>
                  <Async
                    cacheOptions
                    onChange={selectElevationUnit}
                    styles={selectStyle}
                    loadOptions={loadElevationOption}
                    defaultOptions
                    value = {
                      elevationUnitList.filter(option => 
                        option.value === elevationUnit)
                    }
                  ></Async>
                </div>
              </div>
              <div className='project-half-data'>
                <p className="project-name-title">Elevation Offset(0, 10, +10 etc)</p>
                <input type="text" className="project-name-input" 
                  value={elevation} 
                  onChange={onChangeElevation}></input>
              </div>
            </div>
            {(dataSetObject && dataSetObject.status !== "processing")?
            <div className='project-name-box'>
              <p className="project-name-title">CRS</p>
              <Async
                clearable
                onChange={selectCRS}
                styles={selectStyle}
                loadOptions={loadOption}
                value = {
                  epsgList.filter(option => 
                    option.value === epsg_code)
                }
              ></Async>
            </div>:null}
            {(dataSetObject && dataSetObject.status !== "processing")?
            <div className='project-name-box'>
              <div className='settings_component'>
                  <CustomCheckbox checked={merge} onChange={handleMerge} />
              </div>
              <label className='label_settings' onClick={handleMerge}>Merge Process for Lidar and Ortho</label>
            </div>:null}
              
            {(dataSetObject && dataSetObject.status !== "processing")?
            <div className='project-name-box'>
              <div className='settings_component'>
                  <CustomCheckbox checked={compress} onChange={handleCompress} />
              </div>
              <label className='label_settings' onClick={handleCompress}>Compression Process for Ortho</label>
            </div>:null}

            {(dataSetObject && dataSetObject.status !== "processing")?
            <div className='project-name-box'>
              <div className='settings_component'>
                  <CustomCheckbox checked={lidarSubsample} onChange={handleLidarSubsample} />
              </div>
              <label className='label_settings' onClick={handleLidarSubsample}>Lidar Subsample</label>
            </div>:null}

            {(files.length !== 0 && selectedDatasetID !== null)?
            <div className='project-name-box'>
              <Button
                variant="contained"
                color="primary"
                onClick={updateAndUpload}
                className={classes.button}
              >
                {getLabel()}
              </Button>
            </div>:null}
            {(selectedDatasetID !== null)?
            <div className='project-name-box'>
              <Button
                variant="contained"
                color="primary"
                onClick={updateInfo}
                className={classes.button}
              >
                Update only Info
              </Button>
            </div>
            :null}
          </div>
          <div className="project-name-secondary div_relative">
            <div className="replace-check">
              <CustomCheckbox checked={isFileAttach} onChange={handleFileAttach} />
              <div className='replace-check-label'>Files will be added to current Dataset</div>
            </div>
            <div {...getRootProps({ className: 'files-tab' })}>
              {(!uploading)?
              <div className="upload_button_icon can_hover_icon" title="Add Files" onClick={addFiles}>
                <div className="title">Add Files or Drag Drop</div>
                <div className='icon add_button_icon'></div>
                <input
                  style={{display: 'none'}}
                  ref={inputFileRef}
                  type="file"
                  multiple
                  onChange={handleFileChange}
                />
              </div>
              :null}
              <Box>
                <Tabs value={tabValue} onChange={handleTabChange} aria-label="Tabs">
                <Tab label="New Files" {...a11yProps(0)} />
                <Tab label="Existing Files" {...a11yProps(1)} />
                </Tabs>
              </Box>
              <TabPanel value={tabValue} index={0}>
                <div className='dataset-file-list'>
                  <DataGrid
                    rows={tableRows}
                    columns={columns}
                    pageSize={50}
                    rowsPerPageOptions={[50]}
                    getCellClassName = {getFileGridClassName}
                    hideFooter={false}
                    sx={{
                      boxShadow: 1,
                      border: 1,
                      borderColor: '#363739',
                      color: "#797d87",
                      '& .MuiDataGrid-root': {
                          border: 'none'
                      },
                      '& .MuiDataGrid-cell:hover': {
                          color: 'white',
                          cursor: 'pointer'
                      },
                      '& .MuiTablePagination-root': {
                          color: 'white',
                      },
                      "& .MuiPaginationItem-root": {
                          color: "#fff"
                      },
                      "& .MuiTablePagination-actions": {
                          button : {
                              color: "#fff",
                          },
                          "& .Mui-disabled": {
                              color: "#999"
                          }
                      },
                      "& .MuiDataGrid-iconButtonContainer": {
                          button : {
                              color: "#fff",
                          },
                          "& .Mui-disabled": {
                              color: "#999"
                          }
                      },
                      "& .MuiDataGrid-menuIcon" : {
                          display: "none"
                      },
                      "& .MuiDataGrid-overlay": {
                          background: "#17181b"
                      },
                      '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                          outline: 'none',
                      },
                    }}
                  />
                </div>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <div className='dataset-file-list'>
                  <DataGrid
                    rows={currentFiles}
                    columns={existColumns}
                    pageSize={50}
                    disableSelectionOnClick
                    rowsPerPageOptions={[50]}
                    getCellClassName = {getFileGridClassName}
                    hideFooter={false}
                    sx={{
                      boxShadow: 1,
                      border: 1,
                      borderColor: '#363739',
                      color: "#797d87",
                      '& .MuiDataGrid-cell:hover': {
                          color: 'white',
                          cursor: 'pointer'
                      },
                      '& .MuiTablePagination-root': {
                          color: 'white',
                      },
                      "& .MuiPaginationItem-root": {
                          color: "#fff"
                      },
                      "& .MuiTablePagination-actions": {
                          button : {
                              color: "#fff",
                          },
                          "& .Mui-disabled": {
                              color: "#999"
                          }
                      },
                      "& .MuiDataGrid-iconButtonContainer": {
                          button : {
                              color: "#fff",
                          },
                          "& .Mui-disabled": {
                              color: "#999"
                          }
                      },
                      "& .MuiDataGrid-menuIcon" : {
                          display: "none"
                      },
                      "& .MuiDataGrid-overlay": {
                          background: "#17181b"
                      },
                      '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                          outline: 'none',
                      },
                    }}
                  />
                </div>
              </TabPanel>
            </div>
          </div>
        </div>:null}
      </div>
    );
  }

  function FileUploadRenderer() {
    const { getRootProps, getInputProps } = useDropzone({
      onDrop: acceptedFiles => {
        if (acceptedFiles.length > 0) {
          setCaptureDate(new Date(acceptedFiles[0].lastModified));
        }
        setFiles(acceptedFiles);
        generateFileRows(acceptedFiles);
      }
    });
  
    return (
      <div>
      {(files.length === 0 && selectedDatasetID === null) ?
        <section className="container">
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <div className="upload-image-box">
              <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 24 24" className="default-svg">
                <path fill="#AFB9D0" d="M14,13V17H10V13H7L12,8L17,13M19.35,10.03C18.67,6.59 15.64,4 12,4C9.11,4 6.6,5.64 5.35,8.03C2.34,8.36 0,10.9 0,14A6,6 0 0,0 6,20H19A5,5 0 0,0 24,15C24,12.36 21.95,10.22 19.35,10.03Z" />
              </svg>
              <p className='upload-image-text-style'>Drop Or Select Files</p>
              
            </div>
          </div>
        </section>:null}
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div>
        <div className="upload-progress-contaner">
          {(progressing)?<ProgressBar now={uploadProgress} label={progressLabel}/>:null}
        </div>
        <div className="upload-status-contaner">
          {statusMessage !== ""?statusMessage:null}
        </div>
        <div>
          {InfoUpdateRenderer()}
          {FileUploadRenderer()}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
});

HorizontalStepper.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired
}

export default compose(
  withRouter,
  connect(mapStateToProps, globalAction),
  connect(mapStateToProps, authActionCreators),
)(HorizontalStepper);
import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import { globalAction } from '../../store/actions';
import MainView from './MainView';
import Header from '../../components/Header';
import { isExpireTokenLocal } from '../../utils/utilFunctions';
import './style.scss';

function ProcessLayout(props) {
  useEffect(() => {
		if (props.userInfo && props.userInfo.first_name != "" && props.userInfo.role === "viewer") {
      props.history.replace("/map");
    }
	}, [props.userInfo]);

  if (isExpireTokenLocal()) {
		return <Redirect to="/" />
	}

  return (
    <div>
      <Header
        open={props.drawerVisibility}
      />

      <MainView
        open={props.drawerVisibility}
      />
    </div>
  );
}

const mapStateToProps = state => ({
  drawerVisibility: false,
  userInfo: state.global.userInfo,
});

ProcessLayout.propTypes = {
  drawerVisibility: PropTypes.bool.isRequired
}

export default compose(
  withRouter,
  connect(mapStateToProps, globalAction),
)(ProcessLayout);
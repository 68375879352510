import { initialState } from './selectors';
import {
  VOLUME_AREA, VOLUME_PERIMETER, VOLUME_MIN_ELEVATION, VOLUME_MAX_ELEVATION, VOLUME_ELEVATION_DIFFERENCE,
  VOLUME_BASE_SURFACE, VOLUME_CUT_VOLUME, VOLUME_CUT_ERROR, VOLUME_FILL_VOLUME, VOLUME_FILL_ERROR, VOLUME_DIFFERENCE
} from './actions';

export default function volume(state = initialState, action = {}) {
  const { type, payload } = action
  switch (type) {
    // Auth check
    case VOLUME_AREA:
      return {
        ...state,
        volumeArea: payload
      }
    case VOLUME_PERIMETER:
      return {
        ...state,
        volumePerimeter: payload
      }
    case VOLUME_MIN_ELEVATION:
      return {
        ...state,
        volume_min_elevation: payload
      }
    case VOLUME_MAX_ELEVATION:
      return {
        ...state,
        volume_max_elevation: payload
      }
    case VOLUME_ELEVATION_DIFFERENCE:
      return {
        ...state,
        volume_elevation_difference: payload
      }
    case VOLUME_BASE_SURFACE:
      return {
        ...state,
        volume_base_surface: payload
      }
    case VOLUME_CUT_VOLUME:
      return {
        ...state,
        volume_cut_volume: payload
      }
    case VOLUME_CUT_ERROR:
      return {
        ...state,
        volume_cut_error: payload
      }
    case VOLUME_FILL_VOLUME:
      return {
        ...state,
        volume_fill_volume: payload
      }

    case VOLUME_FILL_ERROR:
      return {
        ...state,
        volume_fill_error: payload
      }

    case VOLUME_DIFFERENCE:
      return {
        ...state,
        volume_difference: payload
      }
    default:
      return state;
  }
}

import { initialState } from './selectors';
import {
	TOGGLE_MAP, TOGGLE_TOOL, SELECT_DRAWING_MODE, IMAGERY_3D_TOGGLE,
	IMAGERY_TOGGLE, TERRAIN_TOGGLE, POINT_CLOUD_TOGGLE, SELECT_TINT_COLOR, SELECTED_GROUP_NAME, NOTIFY_NAMES_CHANGED, NOTIFY_ELEVATION_LINE,
	LEN_2D, LEN_3D, MIN_ELEVATION, MAX_ELEVATION, DIFF_ELEVATION,
	MIN_SLOPE, MAX_SLOPE, DIFF_SLOPE, SLOPE_PERCENTAGE, GRAPH_SHOW, GRAPH_CREATED, LINE_SPACING
} from './actions';

export default function map(state = initialState, action = {}) {
	const { type, payload } = action
	switch (type) {
		case TOGGLE_MAP:
			return {
				...state,
				mapType: payload
			}

		case TOGGLE_TOOL:
			return {
				...state,
				toolType: payload
			}

		case SELECT_DRAWING_MODE:
			return {
				...state,
				drawingMode: payload
			}

		case IMAGERY_3D_TOGGLE:
			return {
				...state,
				imagery3DToggled: payload
			}
		case IMAGERY_TOGGLE:
			return {
				...state,
				imageryToggled: payload
			}

		case TERRAIN_TOGGLE:
			return {
				...state,
				terrainToggled: payload
			}

		case POINT_CLOUD_TOGGLE:
			return {
				...state,
				pointCloudToggled: payload
			}

		case SELECT_TINT_COLOR:
			return {
				...state,
				selectedColor: payload
			}

		case SELECTED_GROUP_NAME:
			return {
				...state,
				selectedGroupName: payload
			}

		case NOTIFY_NAMES_CHANGED:
			return {
				...state,
				isNameChanged: payload
			}

		case NOTIFY_ELEVATION_LINE:
			return {
				...state,
				isElevationActivated: payload
			}

		/* annotations line related */
		case LEN_2D:
			return {
				...state,
				len2D: payload
			}

		case LEN_3D:
			return {
				...state,
				len3D: payload
			}

		case MIN_ELEVATION:
			return {
				...state,
				minElevation: payload
			}

		case MAX_ELEVATION:
			return {
				...state,
				maxElevation: payload
			}

		case DIFF_ELEVATION:
			return {
				...state,
				diffElevation: payload
			}

		case MIN_SLOPE:
			return {
				...state,
				minSlope: payload
			}

		case MAX_SLOPE:
			return {
				...state,
				maxSlope: payload
			}

		case DIFF_SLOPE:
			return {
				...state,
				diffSlope: payload
			}

		case SLOPE_PERCENTAGE:
			return {
				...state,
				slopePercentage: payload
			}

		case GRAPH_SHOW:
			return {
				...state,
				graphShow: payload
			}

		case GRAPH_CREATED:
			return {
				...state,
				graphCreated: payload
			}

		case LINE_SPACING:
			return {
				...state,
				lineSpacing: payload
			}
		default:
			return state;
	}
}

export const initialState = {
  isAuthenticated: false,
  calculatePolygonValue: false,
};

export const isAuthenticated = (state = initialState) => (
  state.isAuthenticated || initialState.isAuthenticated
);

export const calculate_polygon = (state = initialState) => (
  state.calculatePolygonValue || initialState.calculatePolygonValue
);
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { globalAction } from '../../store/actions';
import HorizontalStepper from '../../components/Stepper';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  view: {
    marginTop: 100,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  viewShift: {
    marginTop: 80,
    width: `100%`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function MainView(props) {
  const classes = useStyles();
  const drawerVisibility = props.drawerVisibility;
  return (
    <div className={clsx(classes.view, {
      [classes.viewShift]: drawerVisibility,
    })}
    >
      <HorizontalStepper />
    </div>
  );
}

const mapStateToProps = state => ({
  drawerVisibility: state.global.drawerVisibility
});

MainView.propTypes = {
  drawerVisibility: PropTypes.bool.isRequired
}

export default compose(
  withRouter,
  connect(mapStateToProps, globalAction),
)(MainView);
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import SignInScreen from './signin';
import { authActionCreators } from '../../store/actions';

function LoginLayout(props) {
  return (
    <div>
      <SignInScreen/> 
    </div>
  );
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
});

LoginLayout.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired
}

export default compose(
  withRouter,
  connect(mapStateToProps, authActionCreators),
)(LoginLayout);
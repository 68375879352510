
import { createAction } from 'redux-actions';
import { createPromiseAction } from '../../utils/createPromiseAction';

export const TOGGLE_DRAWER = 'global/TOGGLE_DRAWER';
export const TOGGLE_RESET = 'global/TOGGLE_RESET';
export const TAB_SELECTION = 'global/TAB_SELECTION';
export const UPLOAD_FILE_REQUEST = 'global/UPLOAD_FILE_REQUEST';
export const UPLOAD_FILE_SUCCESS = 'global/UPLOAD_FILE_SUCCESS';
export const SET_USER_INFO = 'global/SET_USER_INFO';
export const SET_SHOW_MENU = 'global/SET_SHOW_MENU';

export const globalAction = {
    toggleDrawer: createAction(TOGGLE_DRAWER),
    toggleReset: createAction(TOGGLE_RESET),
    tabSelect: createAction(TAB_SELECTION),
    uploadFile: createPromiseAction (UPLOAD_FILE_REQUEST),
    uploadFileSuccess: createAction (UPLOAD_FILE_SUCCESS),
    setShowMenu: createAction(SET_SHOW_MENU),
    setUserInfo: createAction (SET_USER_INFO),
};
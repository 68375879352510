import React, {useEffect} from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import * as api from '../../utils/api';
import { globalAction, projectAction } from '../../store/actions';
import Select from 'react-select';
import { CircularProgress } from '@mui/material';
import ProgressBar from 'react-bootstrap/ProgressBar'
import { Chart } from "react-google-charts";

import './style.scss';
import { toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
	root: {
        padding: 10,
        overflow: "auto",
        width: "100%"
	},
    info_section: {
        position: 'absolute',
        marginTop: '20px',
        justifyContent: 'center',
        width: 'calc(100% - 20px)',
        height: 'calc(100% - 80px)',
        overflow: "auto"
    },
    panel_label : {
        color: "#2196f3",
        fontSize: 20,
        marginTop: 3,
        textAlign: 'left',
        float: 'left'
    },
    circular_progress: {
        width: 40,
        height: 40,
        position: 'absolute',
    },
    analytics_info: {
        fontSize: 15,
        fontWeight: 400,
        display: "flex",
    },
}));

const selectStyle = {
    control: (base, state) => ({
      ...base,
      background: "#17181b",
      // match with the menu
      borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      borderColor: "#323232",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: "white"
      },
      color: 'white',
      textAlign: 'left',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'white'
    }),
    input: base => ({
      ...base,
      color: "white"
    }),
    option: (styles, {isFocused, isSelected}) => ({
      ...styles,
      background: isFocused?"#27282b":"#17181b",
      color : "white",
      zIndex: 1,
      textAlign: 'left',
    }),
    menu: base => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0
    }),
    menuList: base => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
      border: "1px solid white"
    })
};

function DashboardPanel(props) {
	const classes = useStyles();

    const [projectID, selectProjectID] = React.useState(-1);
    const [projectList, setProjectList] = React.useState([
        {
            value : -1,
            label: "All Projects",
        },
    ]);
    const [progress, setProgress] = React.useState(true);
    const [accountExtended, setAccountExtended] = React.useState(true);
    const [annotationExtended, setAnnotationExtended] = React.useState(false);
    const [imageExtended, setImageExtended] = React.useState(false);
    const [maxSize, setMaxSize] = React.useState(5 * 1024 * 1024 * 1024 * 1024);
    const [annotation_severity, setAnnotationSeverity] = React.useState([]);
    const [image_severity, setImageSeverity] = React.useState([]);

    const annotation_severity_options= {
        legend: 'none',
        slices: {0: {color: '#ed1b24'}, 1:{color: '#fe7e01'}, 2:{color: '#ffff01'}, 3: {color: '#00ff01'}},
        backgroundColor: '#17181b',
        width:150,
        height:150,
        sliceVisibilityThreshold: 0,
        pieSliceTextSlice:{color:'#000000'},
        chartArea: {
            height: '90%',
            width: '90%'
        },
        // tooltip: { trigger: 'none' },
    };

    const image_severity_options= {
        legend: 'none',
        slices: {0: {color: '#ed1b24'}, 1:{color: '#fe7e01'}, 2:{color: '#ffff01'}, 3: {color: '#00ff01'}, 4:{color: '#9e9e9e'}},
        backgroundColor: '#17181b',
        width:160,
        height:160,
        sliceVisibilityThreshold: 0,
        pieSliceTextSlice:{color:'#000000'},
        chartArea: {
            height: '90%',
            width: '90%'
        },
        // tooltip: { trigger: 'none' },
    };

    function redirectToLogin() {
        props.history.replace("/");
    }

    useEffect(() => {
        if (props.userInfo && props.userInfo.max_storage) {
            setMaxSize(props.userInfo.max_storage);
        }
	}, [props.userInfo]);

    useEffect(() => {
        if (props.analyticsInfo && props.allProjects) {
            updateAnalyticsInfo();
        }
	}, [props.allProjects, props.analyticsInfo]);

    useEffect(() => {
        if (props.analyticsInfo && props.allProjects) {
            updatePieChartInfo();
        }
	}, [projectID]);

    function selectProjectList(newValue, actionMeta) {
		if (newValue) {
			if (newValue.value) {
				selectProjectID(newValue.value);
			}
		}
	}

    function updateAnalyticsInfo() {
        let optionValues = [
            {
                value : -1,
                label: "All Projects",
            },
        ];

        for (let i = 0; i < props.allProjects.length; i ++) {
            optionValues.push({
              label: props.allProjects[i].name,
              value: props.allProjects[i].id
            })
        }

        optionValues.sort(function(first, end) {
            return first.label.toLowerCase() < end.label.toLowerCase() ? 1 : -1
        })

        updatePieChartInfo();
        setProjectList(optionValues);
        setProgress(false);
    }

    function updatePieChartInfo() {
        let data = [
            ["Task", "Annotation Severity"],
            ["Red", 0],
            ["Orange", 0],
            ["Yellow", 0],
            ["Green", 0],
        ];

        data[1][1] = getAnnotationCountByType("red");
        data[2][1] = getAnnotationCountByType("orange");
        data[3][1] = getAnnotationCountByType("yellow");
        data[4][1] = getAnnotationCountByType("green");

        setAnnotationSeverity(data);

        let imageData = [
            ["Task", "Image Severity"],
            ["Red", 0],
            ["Orange", 0],
            ["Yellow", 0],
            ["Green", 0],
            ["Grey", 0],
        ];

        imageData[1][1] = getImageCountByType("red");
        imageData[2][1] = getImageCountByType("orange");
        imageData[3][1] = getImageCountByType("yellow");
        imageData[4][1] = getImageCountByType("green");
        imageData[5][1] = getImageCountByType("grey");

        setImageSeverity(imageData);
    }

    function expandAccount() {
        setAccountExtended(!accountExtended);
    }

    function expandAnnotation() {
        setAnnotationExtended(!annotationExtended);
    }

    function expandImages() {
        setImageExtended(!imageExtended);
    }

    async function refreshAnalytics() {
        if (!props.isLatestAnalytics) return;
        let userToken = localStorage.getItem("userToken");
        if (userToken) {
            props.setLatestAnalytics(false);
            let response = await api.getAnalytics({token: userToken});

            if (response.data && !response.data.error) {
                props.setLatestAnalytics(true);
                props.setAnalyticsInfo(response.data);
            }
            else {
                redirectToLogin();
            }
        }
        else {
            redirectToLogin();
        }
    }

    function getTotalStorage() {
        if (!props.analyticsInfo) return 0;
        let calcSize = 0;
        let size_dataset = props.analyticsInfo["size_dataset"];
        for (let i = 0; i < size_dataset.length; i ++) {
            calcSize += parseFloat(size_dataset[i]["dataset_size"]);
            calcSize += parseFloat(size_dataset[i]["process_size"]);
        }

        return Math.min(calcSize, maxSize);
    }

    function getTotalFiles(type) {
        if (!props.analyticsInfo) return 0;
        let size_dataset = props.analyticsInfo["size_dataset"].filter(function(value) {
            return (type === "total" && value.datatype !== "Report") || value.datatype === type;
        });
        return size_dataset.length;
        // let totalCount = 0;
        // for (let i = 0; i < size_dataset.length; i ++) {
        //     totalCount += size_dataset[i].files;
        // }
        
        // return totalCount;
    }

    function getStorageSizeLabel(value) {
        if (value < 1024 * 1024 * 1024) {
            return (value / 1024 / 1024).toFixed(1) + "MB";
        }
        else if (value < 1024 * 1024 * 1024 * 1024) {
            return (value / 1024 / 1024 / 1024).toFixed(1) + "GB";
        }
        else {
            return (value / 1024 / 1024 / 1024 / 1024).toFixed(1) + "TB";
        }
    }

    function getAnnotationCountByType(type) {
        if (!props.analyticsInfo) return 0;
        let project_list  = props.analyticsInfo["project"].filter(function(value) {
            return projectID === -1 || value.id + "" === projectID + "";
        });
        let count = 0;
        for (let i = 0; i < project_list.length; i ++) {
            switch (type) {
                case "joint_use" :
                    count += project_list[i].joint_use_annotation_count;
                    break;
                case "nesc" :
                    count += project_list[i].nesc_annotation_count;
                    break;
                case "other" :
                    count += project_list[i].other_annotation_count;
                    break;
                case "right_of_way" :
                    count += project_list[i].right_of_way_annotation_count;
                    break;
                case "vegetation" :
                    count += project_list[i].vegetation_annotation_count;
                    break;
                case "safety" :
                    count += project_list[i].safety_annotation_count;
                    break;
                case "test_and_treat" :
                    count += project_list[i].test_and_treat_annotation_count;
                    break;
                case "red" :
                    count += project_list[i].red_annotation_count;
                    break;
                case "orange" :
                    count += project_list[i].orange_annotation_count;
                    break;
                case "yellow" :
                    count += project_list[i].yellow_annotation_count;
                    break;
                case "green" :
                    count += project_list[i].green_annotation_count;
                    break;
                case "blue" :
                    count += project_list[i].blue_annotation_count;
                    break;
                case "total" :
                    count += project_list[i].joint_use_annotation_count;
                    count += project_list[i].nesc_annotation_count;
                    count += project_list[i].other_annotation_count;
                    count += project_list[i].right_of_way_annotation_count;
                    count += project_list[i].vegetation_annotation_count;
                    count += project_list[i].safety_annotation_count;
                    count += project_list[i].test_and_treat_annotation_count;
                    break;
            }
        }

        return count;
    }

    function getAnnotationPercentByType(type) {
        if (!props.analyticsInfo) return 0;
        let total = getAnnotationCountByType("total");
        if (total === 0) return 0;
        
        return (getAnnotationCountByType(type) / total * 100).toFixed(1);
    }

    function getImageCountByType(type) {
        if (!props.analyticsInfo) return 0;
        let image_dataset  = props.analyticsInfo["image_dataset"];

        if (projectID !== -1) {
            let datasetList = props.analyticsInfo["project"].filter(function(value) {
                return value.id + "" === projectID + "";
            });

            if (datasetList.length === 0) return 0;
            datasetList = datasetList[0];

            image_dataset = image_dataset.filter(function(value) {
                return datasetList.dataset_ids && datasetList.dataset_ids.indexOf("\"" + value.id + "\"") >= 0;
            });
        }
        let count = 0;
        for (let i = 0; i < image_dataset.length; i ++) {
            switch (type) {
                case "red" :
                    count += image_dataset[i].red_image_count;
                    break;
                case "orange" :
                    count += image_dataset[i].orange_image_count;
                    break;
                case "yellow" :
                    count += image_dataset[i].yellow_image_count;
                    break;
                case "green" :
                    count += image_dataset[i].green_image_count;
                    break;
                case "blue" :
                    count += image_dataset[i].blue_image_count;
                    break;
                case "grey" :
                    count += image_dataset[i].grey_image_count;
                    break;
                case "total" :
                    count += image_dataset[i].red_image_count;
                    count += image_dataset[i].orange_image_count;
                    count += image_dataset[i].yellow_image_count;
                    count += image_dataset[i].green_image_count;
                    count += image_dataset[i].grey_image_count;
                    break;
            }
        }

        return count;
    }

    function getImagePercentByType(type) {
        if (!props.analyticsInfo) return 0;
        let total = getImageCountByType("total");
        if (total === 0) return 0;
        
        return (getImageCountByType(type) / total * 100).toFixed(1);
    }

	return (
		<div className={classes.root}>
            <div className='dashboard_title_part'>
                <div className={classes.panel_label}>Dashboard</div>
                <div className="can_hover_icon dataset_refresh no_boder_line analytics_refresh" ><div className='icon refresh_icon' onClick={refreshAnalytics}></div></div>
                <div className='select_project_list'>
                    <Select
                        isMulti={false}
                        onChange={selectProjectList}
                        styles={selectStyle}
                        options={projectList}
                        isSearchable={false}
                        value = {
                            projectList.filter(function (option) {
                                return option.value === projectID;
                            })
                        }
                    ></Select>
                </div>
            </div>

            <div className={classes.info_section}>
                {progress? 
                    <CircularProgress className={classes.circular_progress}/>
                :
                    <div>
                        {!props.isLatestAnalytics?
                        <div className="latest_part">Last Analytics (Updating)</div>
                        :null}
                        <div className="project_dataset_item top_border account_part">
                            <div className={classes.analytics_info}>
                                <div className="analytics_icon can_hover_icon no_boder_line" onClick={expandAccount}>
                                    {accountExtended?
                                        <div className='icon below_icon'></div>
                                    :
                                        <div className='icon up_icon'></div>
                                    }
                                </div>
                                <div className="analytics_part_title" onClick={expandAccount}>ACCOUNT STATISTICS</div>
                            </div>
                            {accountExtended?
                                <div>
                                    <ProgressBar now={Math.floor(getTotalStorage() / maxSize * 100)} label={Math.floor(getTotalStorage() / maxSize * 100) + "%"} className="analytics_progress"/>
                                    <div className='analytics_account_info'>
                                        <div className='analytics_account_info_label'>Storage</div>
                                        <div className='analytics_account_info_value'>{getStorageSizeLabel(getTotalStorage())} of {getStorageSizeLabel(maxSize)}</div>
                                    </div>
                                    <div className='analytics_account_info'>
                                        <div className='analytics_account_info_label'>Projects</div>
                                        <div className='analytics_account_info_value'>{(props.analyticsInfo?props.analyticsInfo["project"].length:0)}</div>
                                    </div>
                                    <div className='analytics_account_info'>
                                        <div className='analytics_account_info_label'>Datasets</div>
                                        <div className='analytics_account_info_value'>{getTotalFiles("total")}</div>
                                    </div>
                                    <div className='analytics_account_info'>
                                        <div className='analytics_account_info_label'>Reports</div>
                                        <div className='analytics_account_info_value'>{getTotalFiles("Report")}</div>
                                    </div>
                                    <div className='analytics_account_info'>
                                        <div className='analytics_account_info_label'>Images</div>
                                        <div className='analytics_account_info_value'>{getTotalFiles("img")}</div>
                                    </div>
                                    <div className='analytics_account_info'>
                                        <div className='analytics_account_info_label'>KMZ/KML</div>
                                        <div className='analytics_account_info_value'>{getTotalFiles("kml")}</div>
                                    </div>
                                    <div className='analytics_account_info'>
                                        <div className='analytics_account_info_label'>Lidar</div>
                                        <div className='analytics_account_info_value'>{getTotalFiles("pointcloud")}</div>
                                    </div>
                                    <div className='analytics_account_info'>
                                        <div className='analytics_account_info_label'>Ortho</div>
                                        <div className='analytics_account_info_value'>{getTotalFiles("tiff")}</div>
                                    </div>
                                </div>
                            :null}
                        </div>

                        <div className="project_dataset_item annotation_part">
                            <div className={classes.analytics_info}>
                                <div className="analytics_icon can_hover_icon no_boder_line" onClick={expandAnnotation}>
                                    {annotationExtended?
                                        <div className='icon below_icon'></div>
                                    :
                                        <div className='icon up_icon'></div>
                                    }
                                </div>
                                <div className="analytics_part_title" onClick={expandAnnotation}>ANNOTATION STATISTICS</div>
                            </div>
                            {annotationExtended?
                                <div>
                                    <div className='annotation_type_part'>
                                        <div className='annotation_type_info_part'>
                                            <div className='analytics_account_info'>
                                                <div className='analytics_account_info_label blue_color_text'>TYPE</div>
                                                <div className='analytics_account_info_value blue_color_text'>(#)</div>
                                            </div>
                                            <div className='analytics_account_info'>
                                                <div className='analytics_account_info_label'>Joint USE</div>
                                                <div className='analytics_account_info_value'>{getAnnotationCountByType("joint_use")}</div>
                                            </div>
                                            <div className='analytics_account_info'>
                                                <div className='analytics_account_info_label'>NESC</div>
                                                <div className='analytics_account_info_value'>{getAnnotationCountByType("nesc")}</div>
                                            </div>
                                            <div className='analytics_account_info'>
                                                <div className='analytics_account_info_label'>Other</div>
                                                <div className='analytics_account_info_value'>{getAnnotationCountByType("other")}</div>
                                            </div>
                                            <div className='analytics_account_info'>
                                                <div className='analytics_account_info_label'>Vegetation</div>
                                                <div className='analytics_account_info_value'>{getAnnotationCountByType("vegetation")}</div>
                                            </div>
                                            <div className='analytics_account_info'>
                                                <div className='analytics_account_info_label'>Right of Way</div>
                                                <div className='analytics_account_info_value'>{getAnnotationCountByType("right_of_way")}</div>
                                            </div>
                                            <div className='analytics_account_info'>
                                                <div className='analytics_account_info_label'>Safety</div>
                                                <div className='analytics_account_info_value'>{getAnnotationCountByType("safety")}</div>
                                            </div>
                                            <div className='analytics_account_info'>
                                                <div className='analytics_account_info_label'>Test and Treat</div>
                                                <div className='analytics_account_info_value'>{getAnnotationCountByType("test_and_treat")}</div>
                                            </div>
                                            <div className='analytics_separate_line'>
                                            </div>
                                            <div className='analytics_account_info'>
                                                <div className='analytics_account_info_label'>TOTAL</div>
                                                <div className='analytics_account_info_value'>{getAnnotationCountByType("total")}</div>
                                            </div>
                                        </div>
                                        <div className='annotation_type_percent_part'>
                                            <div className='analytics_account_info_label blue_color_text'>(%)</div>
                                            <div className='analytics_account_percent_layout'>
                                                <div className='analytics_separate_line_vertical_line'></div>
                                                <div className='annotation_type_percent_real_part'>
                                                    <ProgressBar now={getAnnotationPercentByType("joint_use")} label={getAnnotationPercentByType("joint_use") + "%"} className="analytics_annotation_horiz_progress"/>
                                                    <ProgressBar now={getAnnotationPercentByType("nesc")} label={getAnnotationPercentByType("nesc") + "%"} className="analytics_annotation_horiz_progress"/>
                                                    <ProgressBar now={getAnnotationPercentByType("other")} label={getAnnotationPercentByType("other") + "%"} className="analytics_annotation_horiz_progress"/>
                                                    <ProgressBar now={getAnnotationPercentByType("vegetation")} label={getAnnotationPercentByType("vegetation") + "%"} className="analytics_annotation_horiz_progress"/>
                                                    <ProgressBar now={getAnnotationPercentByType("right_of_way")} label={getAnnotationPercentByType("right_of_way") + "%"} className="analytics_annotation_horiz_progress"/>
                                                    <ProgressBar now={getAnnotationPercentByType("safety")} label={getAnnotationPercentByType("safety") + "%"} className="analytics_annotation_horiz_progress"/>
                                                    <ProgressBar now={getAnnotationPercentByType("test_and_treat")} label={getAnnotationPercentByType("test_and_treat") + "%"} className="analytics_annotation_horiz_progress"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='annotation_severity_part'>
                                        <div className='annotation_type_info_part'>
                                            <div className='analytics_account_info'>
                                                <div className='analytics_account_info_label blue_color_text'>Severity</div>
                                                <div className='analytics_account_info_value blue_color_text'>(#)</div>
                                            </div>
                                            <div className='analytics_account_info'>
                                                <div className='analytics_account_info_label red_color'>Red (very severe)</div>
                                                <div className='analytics_account_info_value red_color'>{getAnnotationCountByType("red")}</div>
                                            </div>
                                            <div className='analytics_account_info'>
                                                <div className='analytics_account_info_label orange_color'>Orange (severe)</div>
                                                <div className='analytics_account_info_value orange_color'>{getAnnotationCountByType("orange")}</div>
                                            </div>
                                            <div className='analytics_account_info'>
                                                <div className='analytics_account_info_label yellow_color'>Yellow (mild)</div>
                                                <div className='analytics_account_info_value yellow_color'>{getAnnotationCountByType("yellow")}</div>
                                            </div>
                                            <div className='analytics_account_info'>
                                                <div className='analytics_account_info_label green_color'>Green (notes)</div>
                                                <div className='analytics_account_info_value green_color'>{getAnnotationCountByType("green")}</div>
                                            </div>
                                            <div className='analytics_separate_line'>
                                            </div>
                                            <div className='analytics_account_info'>
                                                <div className='analytics_account_info_label'>TOTAL</div>
                                                <div className='analytics_account_info_value'>{getAnnotationCountByType("total")}</div>
                                            </div>
                                        </div>
                                        <div className='annotation_severity_percent_part'>
                                            <div className='analytics_account_info_label blue_color_text'>(%)</div>
                                            <div className='analytics_account_percent_layout'>
                                                <div className='annotation_severity_percent_real_part'>
                                                    {getAnnotationCountByType("total") !== 0?
                                                        <Chart
                                                            chartType="PieChart"
                                                            data={annotation_severity}
                                                            options={annotation_severity_options}
                                                            width={"150px"}
                                                            height={"150px"}
                                                        />
                                                    :null}
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            :null}
                        </div>

                        <div className="project_dataset_item image_info_part">
                            <div className={classes.analytics_info}>
                                <div className="analytics_icon can_hover_icon no_boder_line" onClick={expandImages}>
                                    {imageExtended?
                                        <div className='icon below_icon'></div>
                                    :
                                        <div className='icon up_icon'></div>
                                    }
                                </div>
                                <div className="analytics_part_title" onClick={expandImages}>IMAGE STATISTICS</div>
                            </div>
                            {imageExtended?
                                <div>
                                    <div className='image_severity_part'>
                                        <div className='image_severity_info_part'>
                                            <div className='analytics_account_info'>
                                                <div className='analytics_account_info_label blue_color_text'>Severity</div>
                                                <div className='analytics_account_info_value blue_color_text'>(#)</div>
                                            </div>
                                            <div className='analytics_account_info'>
                                                <div className='analytics_account_info_label red_color'>Red (very severe)</div>
                                                <div className='analytics_account_info_value red_color'>{getImageCountByType("red")}</div>
                                            </div>
                                            <div className='analytics_account_info'>
                                                <div className='analytics_account_info_label orange_color'>Orange (severe)</div>
                                                <div className='analytics_account_info_value orange_color'>{getImageCountByType("orange")}</div>
                                            </div>
                                            <div className='analytics_account_info'>
                                                <div className='analytics_account_info_label yellow_color'>Yellow (mild)</div>
                                                <div className='analytics_account_info_value yellow_color'>{getImageCountByType("yellow")}</div>
                                            </div>
                                            <div className='analytics_account_info'>
                                                <div className='analytics_account_info_label green_color'>Green (notes)</div>
                                                <div className='analytics_account_info_value green_color'>{getImageCountByType("green")}</div>
                                            </div>
                                            <div className='analytics_account_info'>
                                                <div className='analytics_account_info_label grey_color'>Grey (not inspected)</div>
                                                <div className='analytics_account_info_value grey_color'>{getImageCountByType("grey")}</div>
                                            </div>
                                            <div className='analytics_separate_line'>
                                            </div>
                                            <div className='analytics_account_info'>
                                                <div className='analytics_account_info_label'>TOTAL</div>
                                                <div className='analytics_account_info_value'>{getImageCountByType("total")}</div>
                                            </div>
                                        </div>
                                        <div className='image_severity_percent_part'>
                                            <div className='analytics_account_info_label blue_color_text'>(%)</div>
                                            <div className='analytics_account_percent_layout'>
                                                <div className='annotation_severity_percent_real_part'>
                                                    {getImageCountByType("total") !== 0?
                                                        <Chart
                                                            chartType="PieChart"
                                                            data={image_severity}
                                                            options={image_severity_options}
                                                            width={"160px"}
                                                            height={"160px"}
                                                        />
                                                    :null}
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            :null}
                        </div>
                    </div> 
                }
            </div>
		</div>
	);
}

const mapStateToProps = state => ({
    userInfo: state.global.userInfo,
    analyticsInfo : state.project.analyticsInfo,
    allProjects : state.project.allProjects,
    isLatestAnalytics : state.project.isLatestAnalytics,
});

DashboardPanel.propTypes = {
}
export default compose(
	withRouter,
	connect(mapStateToProps, globalAction),
	connect(mapStateToProps, projectAction),
)(DashboardPanel);

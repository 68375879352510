import React, { useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import _ from 'lodash';

import { globalAction } from '../../store/actions';
import { projectAction } from '../../store/actions';
import { mapAction } from '../../store/actions';
import './style.scss';
import Toolbar from './Toolbar';

import * as mapUtils from './MapUtils';
import * as api from '../../utils/api';

import {Modal} from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import Select from 'react-select';
import Radio from '@mui/material/Radio';
import { faLaptopHouse, fas } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

const selectStyle = {
    control: (base, state) => ({
      ...base,
      background: "#17181b",
      // match with the menu
      borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      borderColor: "#323232",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: "white"
      },
      color: 'white',
	  height: 40,
      textAlign: 'left',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'white'
    }),
    input: base => ({
      ...base,
      color: "white"
    }),
    option: (styles, {isFocused, isSelected}) => ({
      ...styles,
      background: isFocused?"#27282b":"#17181b",
      color : "white",
      zIndex: 1,
      textAlign: 'left',
    }),
    menu: base => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
	  zIndex: 9999,
    }),
    menuList: base => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
	  border: "1px solid white"
    })
};

const Cesium = window.Cesium;

window.previousAssets = [];
window.highlightedLabels = {};
window.highlightedLinePoints = {};
window.highlightedPolygonPoints = {};

const useStyles = makeStyles(theme => ({
	view: {
		position: 'relative',
		width: '100%',
		height: '100vh',
		overflow: 'hidden',
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	viewShift: {
		position: 'relative',
		height: '100vh',
		overflow: 'hidden',
		width: `100%`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	button: {
        fontSize: 15,
        fontWeight: 400,
        marginRight: 20,
    },
	annotation_button: {
        fontSize: 15,
        fontWeight: 400,
        marginRight: 20,
		marginTop: 20,
    },
}));

function useReferredState(initialValue) {
    const [state, setState] = React.useState(initialValue);
    const reference = React.useRef(state);

    const setReferredState = value => {
        reference.current = value;
        setState(value);
    };

    return [reference, setReferredState];
}

function MapView(props) {
	/* state variables */
	const classes = useStyles();

	var mouseHandler;

	const [anchorPosition, setAnchorPosition] = React.useState(null);
	const isShowingContextMenu = Boolean(anchorPosition);

	const [isCreatingLidarAnnotation, setCreatingLidarAnnotation] = React.useState(false);
	const [creatingAnnotationName, setCreatingAnnotationName] = React.useState("");
    const [creatingAnnotationDescription, setCreatingAnnotationDescription] = React.useState("");

	const [showingImageFile, setShowingImageFile] = React.useState(null);
	const isShowingImageView = Boolean(showingImageFile);

	const [autoCreateAnnotation, setAutoCreateAnnotation] = React.useState(false);
	const [annotationType, setAnnotationType] = React.useState("other");
	const [annotationStatus, setAnnotationStatus] = React.useState("0");
	const [severitylevel, setSeverityLevel] = useReferredState(1);
	const [isShowingAnnotationMenu, setShowingAnnotationMenu] = React.useState(true);
	const [fullscreen, setFullScreen] = React.useState(false);

	const [annotationTypeList, setAnnotationTypeList] = React.useState([]);
    const [annotationStatusList, setAnnotationStatusList] = React.useState([]);

	function handleContextMenu(event) {
		event.preventDefault();
	}

	function initViewer() {
		document.addEventListener('mousedown', function (event) {
			if (event.detail > 1) {
			  event.preventDefault();
			}
		}, false);
		
		if (window.MAIN_CESIUM_VIEWER) {
			document.getElementById("cesium-wrapper").appendChild(window.MAIN_CESIUM_VIEWER._container);
			window.MAIN_CESIUM_VIEWER.useDefaultRenderLoop = true;

			let search_element = document.getElementsByClassName("cesium-viewer-toolbar");
			let boxElement = document.getElementById("search-box");
			if (search_element && boxElement && search_element.length > 0) {
				boxElement.appendChild(search_element[0]);
			}
		}
		else {
			var div = document.createElement('div');
			div.id = 'cesium-content';
			div.className = 'viewer-cesium-content';

			document.getElementById("cesium-wrapper").appendChild(div);

			window.MAIN_CESIUM_VIEWER = new Cesium.Viewer('cesium-content', {
				baseLayerPicker: false,
				sceneModePicker: false,
				homeButton: false,
				animation: false,
				infoBox: false,
				navigationHelpButton: false,
				fullscreenButton: false,
				timeline: false,
				selectionIndicator: false,
				terrainProvider: Cesium.createWorldTerrain({
					requestWaterMask: true,
					requestVertexNormals: true
				}),
			});

			window.MAIN_CESIUM_VIEWER.is2D = false;

			window.MAIN_CESIUM_VIEWER.morphTime = 1.0;

			window.MAIN_CESIUM_VIEWER.scene.globe.depthTestAgainstTerrain = false;
		}

		window.MAIN_CESIUM_VIEWER.scene.globe.baseColor = new Cesium.Color(0, 0, 0, 1);

		let longitude = api.getParameterByName("longitude");
		let latitude = api.getParameterByName("latitude");
		let altitude = api.getParameterByName("altitude");
		if (!altitude || altitude === "" || altitude === "undefined") altitude = "0";
		if (longitude && longitude != "" && latitude && latitude != "") {
			mapUtils.flyPositionWithoutDuration(window.MAIN_CESIUM_VIEWER, {
				longitude: parseFloat(longitude),
				latitude: parseFloat(latitude),
				altitude: parseFloat(altitude)
			});
		}
	}

	function updateGISPositionByMouse(mousePosition) {
		let position = mapUtils.getGlobePositionWithTerrain(window.MAIN_CESIUM_VIEWER, mousePosition);
		if (!position) {
			document.getElementById("location_label").style.display = "none";
			return;
		}
		var lon = Cesium.Math.toDegrees(position.longitude);
		var lat = Cesium.Math.toDegrees(position.latitude);
		var height = position.height;
		document.getElementById("location_label").style.display = "block";
		document.getElementById("gis_location").innerHTML = mapUtils.locationToString(lon, lat);
		document.getElementById("gis_location").style.display = "inline-block";
		document.getElementById("gis_elevation").innerHTML = mapUtils.getHeightFromLocation(height);
		document.getElementById("gis_elevation").style.display = "inline-block";
	}


	function downClickAction(movement) {
		handleMenuClose();
	}

	function showCreateAnnotationDilaog() {
		if (window.enable_annotation_popup) {
			setCreatingAnnotationName("Untitled " + window.typeMeasurement);
			setCreatingAnnotationDescription("");
			setCreatingLidarAnnotation(true);
		}
		else {
			setAutoCreateAnnotation(!autoCreateAnnotation);
		}
	}

	function leftClickAction(event) {
		if (window.addingPole) {
			if (window.addingPoleItem) {
				props.setAddingPosition(window.addingPoleItem._position._value);
			}
			else {
				window.addingPole = false;
			}
		}
		else if (window.addingMeasurement) {
			if (window.typeMeasurement === "marker") {
				if (window.measurementMarker) {
					window.addingMeasurement = false;

					showCreateAnnotationDilaog();
				}
			}
			else if (window.typeMeasurement === "distance" && window.measurementPoints) {
				let position = mapUtils.getWorldPosition(window.MAIN_CESIUM_VIEWER.scene, event.position);
				if (window.measurementPoints.length === 1) {
					window.measurementPoints.push(window.MAIN_CESIUM_VIEWER.entities.add({
						position: (position)?position:new Cesium.Cartesian3(0, 0, 0),
						point: {
							pixelSize: 10,
							color: Cesium.Color.fromCssColorString(mapUtils.getSeverityColor(severitylevel.current)).withAlpha(1),
							disableDepthTestDistance: Number.POSITIVE_INFINITY,
						},
					}));
				}
				else if (window.measurementPoints.length === 2) {
					window.addingMeasurement = false;
					showCreateAnnotationDilaog();
				}
			}
			else if (window.typeMeasurement === "polyline" && window.measurementPoints) {
				let position = mapUtils.getWorldPosition(window.MAIN_CESIUM_VIEWER.scene, event.position);
				if (window.measurementPoints.length >= 1) {
					window.measurementPoints.push(window.MAIN_CESIUM_VIEWER.entities.add({
						position: (position)?position:new Cesium.Cartesian3(0, 0, 0),
						point: {
							pixelSize: 10,
							color: Cesium.Color.fromCssColorString(mapUtils.getSeverityColor(severitylevel.current)).withAlpha(1),
							disableDepthTestDistance: Number.POSITIVE_INFINITY,
						},
					}));
				}
			}
		}
		else {
			let pickedItem = mapUtils.pickPoint(window.MAIN_CESIUM_VIEWER, event.position);
			if (pickedItem) {
				if (pickedItem._id.indexOf("image_item_") === 0 && !showingImageFile) {
					displayImagePopup(pickedItem.project, pickedItem.dataset, pickedItem.file);
				}
				else if (pickedItem.report_type && pickedItem.report_type === "report_image_item") {
					if (pickedItem.project && pickedItem.dataset && pickedItem.file && !showingImageFile) {
						displayImagePopup(pickedItem.project, pickedItem.dataset, pickedItem.file)
					}
				}
			}
			else {
				api.setParameter("show_panel", "");
				props.setShowMenu(false);
			}
		}
	}

	function displayImagePopup(project, dataset, file) {
		file.project_id = project.id;
		file.dataset_id = dataset.id;
		file.dataset = dataset
		setShowingImageFile(file);
	}

	function rightClickAction(event) {
		if (!api.canEditItem(window.userInfo)) {
			return;
		}
		if (!window.addingMeasurement) {
			setAnchorPosition({
				x : event.position.x,
				y: event.position.y
			});
		}
		else if (window.addingMeasurement) {
			if (window.typeMeasurement === "polyline" && window.measurementPoints) {
				window.addingMeasurement = false;
				showCreateAnnotationDilaog();
			}
		}
	}

	function mouseMoveAction(windowPosition) {
		updateGISPositionByMouse(windowPosition);

		if (window.addingPole) {
			let position = mapUtils.getWorldPosition(window.MAIN_CESIUM_VIEWER.scene, windowPosition);
			if (!window.addingPoleItem) {
				window.addingPoleItem = window.MAIN_CESIUM_VIEWER.entities.add({
					position: position,
					billboard: {
						image: '/images/pole' + props.userInfo.pole_icon + '.png',
						color: Cesium.Color.fromCssColorString(props.userInfo.style_color).withAlpha(1),
						horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
						verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
						width: 50,
						height: 50,
						scaleByDistance: new Cesium.NearFarScalar(2000000, 1, 16000000, 0.1),
						disableDepthTestDistance: Number.POSITIVE_INFINITY
					}
				});
			}
			else {
				if (window.addingPoleItem._position) {
					window.addingPoleItem._position._value = position;
				}
			}
		}
		else if (window.addingMeasurement) {
			if (window.typeMeasurement === "marker") {
				let position = mapUtils.getWorldPosition(window.MAIN_CESIUM_VIEWER.scene, windowPosition);
				if (position) {
					if (!window.measurementMarker) {
						window.measurementMarker = window.MAIN_CESIUM_VIEWER.entities.add({
							position: position,
							billboard: {
								image: '/images/' + severitylevel.current + '_marker.png',
								horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
								verticalOrigin: Cesium.VerticalOrigin.CENTER,
								width: 30,
								height: 30,
								scaleByDistance: new Cesium.NearFarScalar(2000000, 1, 16000000, 0.1),
								disableDepthTestDistance: Number.POSITIVE_INFINITY,
								color: Cesium.Color.fromCssColorString(mapUtils.getSeverityColor(severitylevel.current)).withAlpha(1)
							}
						});
					}
					else {
						window.measurementMarker._position._value = position;
					}
				}
			}
			else if (window.typeMeasurement === "distance") {
				let position = mapUtils.getWorldPosition(window.MAIN_CESIUM_VIEWER.scene, windowPosition);
				if (position) {
					if (!window.measurementPoints) {
						window.measurementPoints = [];
						window.measurementPoints.push(window.MAIN_CESIUM_VIEWER.entities.add({
							position: position,
							point: {
								pixelSize: 10,
								color: Cesium.Color.fromCssColorString(mapUtils.getSeverityColor(severitylevel.current)).withAlpha(1),
								disableDepthTestDistance: Number.POSITIVE_INFINITY,
							},
						}));
						
					}
					else {
						window.measurementPoints[window.measurementPoints.length - 1]._position._value = position;
						if (!window.measurementDistance && window.measurementPoints.length === 2) {
							let position0 = window.measurementPoints[0]._position._value;
							let position1 = window.measurementPoints[1]._position._value;
							let distance = Cesium.Cartesian3.distance(position0, position1);
							window.measurementDistance = window.MAIN_CESIUM_VIEWER.entities.add({
								polyline: {
									positions: [position0, position1],
									material: new Cesium.PolylineGlowMaterialProperty({
										color: Cesium.Color.fromCssColorString(mapUtils.getSeverityColor(severitylevel.current)).withAlpha(1)
									}),
									depthFailMaterial : new Cesium.PolylineGlowMaterialProperty({
										color: Cesium.Color.fromCssColorString(mapUtils.getSeverityColor(severitylevel.current)).withAlpha(1)
									}),
									width: 6,
									disableDepthTestDistance: Number.POSITIVE_INFINITY,
								}
							});
	
							window.measurementLabel = window.MAIN_CESIUM_VIEWER.entities.add({
								position: new Cesium.Cartesian3((position0.x + position1.x) / 2, (position0.y + position1.y) / 2, (position0.z + position1.z) / 2),
								label: {
									text: mapUtils.getLabelOfMeasure(distance, props.userInfo.measurement_units),
									font: "20px Helvetica",
									showBackground: true,
									fillColor: Cesium.Color.BLACK,
									outlineColor: Cesium.Color.WHITE,
									outlineWidth: 3,
									style: Cesium.LabelStyle.FILL_AND_OUTLINE,
									horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
									pixelOffset : new Cesium.Cartesian2(20, 0),
									disableDepthTestDistance: Number.POSITIVE_INFINITY,
								},
							});
							window.measurementLabel.distanceValue = distance;
						}
						else if (window.measurementDistance) {
							let position0 = window.measurementPoints[0]._position._value;
							let position1 = window.measurementPoints[1]._position._value;
							let distance = Cesium.Cartesian3.distance(position0, position1);
							window.measurementDistance.polyline.positions = [position0, position1];
							window.measurementLabel.distanceValue = distance;
							window.measurementLabel._label._text._value = mapUtils.getLabelOfMeasure(distance, props.userInfo.measurement_units)
							window.measurementLabel._position._value = new Cesium.Cartesian3((position0.x + position1.x) / 2, (position0.y + position1.y) / 2, (position0.z + position1.z) / 2);
						}
					}
				}
			}
			else if (window.typeMeasurement === "polyline") {
				let position = mapUtils.getWorldPosition(window.MAIN_CESIUM_VIEWER.scene, windowPosition);
				if (position) {
					if (!window.measurementPoints) {
						window.measurementPoints = [];
						window.measurementPoints.push(window.MAIN_CESIUM_VIEWER.entities.add({
							position: position,
							point: {
								pixelSize: 10,
								color: Cesium.Color.fromCssColorString(mapUtils.getSeverityColor(severitylevel.current)).withAlpha(1),
								disableDepthTestDistance: Number.POSITIVE_INFINITY,
							},
						}));
						
					}
					else {
						window.measurementPoints[window.measurementPoints.length - 1]._position._value = position;
						if (window.measurementPoints.length >= 2) {
							if (!window.measurementDistance) {
								window.measurementDistance = [];
								window.measurementLabel = [];
							}
							if (!window.measurementDistance[window.measurementPoints.length - 2]) {
								let pointsCount = window.measurementPoints.length;
								let position0 = window.measurementPoints[pointsCount - 2]._position._value;
								let position1 = window.measurementPoints[pointsCount - 1]._position._value;
								let distance = Cesium.Cartesian3.distance(position0, position1);
								window.measurementDistance.push(window.MAIN_CESIUM_VIEWER.entities.add({
									polyline: {
										positions: [position0, position1],
										material: new Cesium.PolylineGlowMaterialProperty({
											color: Cesium.Color.fromCssColorString(mapUtils.getSeverityColor(severitylevel.current)).withAlpha(1)
										}),
										depthFailMaterial : new Cesium.PolylineGlowMaterialProperty({
											color: Cesium.Color.fromCssColorString(mapUtils.getSeverityColor(severitylevel.current)).withAlpha(1)
										}),
										width: 6,
										disableDepthTestDistance: Number.POSITIVE_INFINITY,
									}
								}));
		
								window.measurementLabel.push(window.MAIN_CESIUM_VIEWER.entities.add({
									position: new Cesium.Cartesian3((position0.x + position1.x) / 2, (position0.y + position1.y) / 2, (position0.z + position1.z) / 2),
									label: {
										text: mapUtils.getLabelOfMeasure(distance, props.userInfo.measurement_units),
										font: "20px Helvetica",
										showBackground: true,
										fillColor: Cesium.Color.BLACK,
										outlineColor: Cesium.Color.WHITE,
										outlineWidth: 3,
										style: Cesium.LabelStyle.FILL_AND_OUTLINE,
										horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
										pixelOffset : new Cesium.Cartesian2(20, 0),
										disableDepthTestDistance: Number.POSITIVE_INFINITY,
									},
								}));
								window.measurementLabel[window.measurementLabel.length - 1].distanceValue = distance;
							}
							else {
								let pointsCount = window.measurementPoints.length;
								let position0 = window.measurementPoints[pointsCount - 2]._position._value;
								let position1 = window.measurementPoints[pointsCount - 1]._position._value;
								let distance = Cesium.Cartesian3.distance(position0, position1);
								window.measurementDistance[pointsCount - 2].polyline.positions = [position0, position1];
								window.measurementLabel[pointsCount - 2]._label._text._value = mapUtils.getLabelOfMeasure(distance, props.userInfo.measurement_units);
								window.measurementLabel[pointsCount - 2].distanceValue = distance;
								window.measurementLabel[pointsCount - 2]._position._value = new Cesium.Cartesian3((position0.x + position1.x) / 2, (position0.y + position1.y) / 2, (position0.z + position1.z) / 2);
							}
							
						}
					}
				}
			}
		}
	}

	function upClickAction(movement) {
		handleMenuClose();
	}

	function flyToPosition(event) {
		let position = mapUtils.getGlobePositionWithTerrain(window.MAIN_CESIUM_VIEWER, event.position);

		var lon = Cesium.Math.toDegrees(position.longitude);
		var lat = Cesium.Math.toDegrees(position.latitude);
		var height = window.MAIN_CESIUM_VIEWER.scene.globe.getHeight(position) + 3000;
		mapUtils.flyPosition(window.MAIN_CESIUM_VIEWER, {
			longitude: lon,
			latitude: lat,
			altitude: height
		});
	}

	function setMouseEventHandlers() {
		mouseHandler = new Cesium.ScreenSpaceEventHandler(window.MAIN_CESIUM_VIEWER.scene.canvas);

		mouseHandler.setInputAction(function (movement) {
			mouseMoveAction(movement.endPosition);
		}, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

		mouseHandler.setInputAction(function (event) {
			leftClickAction(event);
		}, Cesium.ScreenSpaceEventType.LEFT_CLICK);

		mouseHandler.setInputAction(function (movement) {
			upClickAction(movement);
		}, Cesium.ScreenSpaceEventType.LEFT_UP);

		mouseHandler.setInputAction(function (movement) {
			downClickAction(movement);
		}, Cesium.ScreenSpaceEventType.LEFT_DOWN);

		mouseHandler.setInputAction(function (event) {
			rightClickAction(event);
		}, Cesium.ScreenSpaceEventType.RIGHT_CLICK);

		window.MAIN_CESIUM_VIEWER.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);

		mouseHandler.setInputAction(function (event) {
			flyToPosition(event)
		}, Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);

		window.MAIN_CESIUM_VIEWER.camera.percentageChanged = 0.01;

		window.MAIN_CESIUM_VIEWER.camera.changed.addEventListener(function () {
			let cartographicPosition = Cesium.Ellipsoid.WGS84.cartesianToCartographic(window.MAIN_CESIUM_VIEWER.camera.position);
			api.setParameter("longitude", Cesium.Math.toDegrees(cartographicPosition.longitude));
			api.setParameter("latitude", Cesium.Math.toDegrees(cartographicPosition.latitude));
			api.setParameter("altitude", cartographicPosition.height);
		});
	};

	useEffect(() => {
		if (props.mapType === 0) {
			_toggle2DMap();
		} else {
			_toggle3DMap();
		}
	}, [props.mapType]);

	useEffect(() => {
		window.userInfo = props.userInfo;
		if (props.userInfo.annotation_type_list === "" || !props.userInfo.annotation_type_list) {
            setAnnotationTypeList(mapUtils.generateAnnotationTypeList());
        }
        else {
            setAnnotationTypeList(JSON.parse(props.userInfo.annotation_type_list));
        }

        if (props.userInfo.annotation_status_list === "" || !props.userInfo.annotation_status_list) {
            setAnnotationStatusList(mapUtils.generateAnnotationStatusList());
        }
        else {
            setAnnotationStatusList(JSON.parse(props.userInfo.annotation_status_list));
        }
		resetAndLoadProjectData();
	}, [props.datasetList, props.userInfo]);

	useEffect(() => {
		resetAndLoadAllAssets();
	}, [props.allAssets, props.labelProperty, props.userInfo]);

	useEffect(() => {
		window.enable_annotation_popup = props.userInfo.enable_annotation_popup;
		mapUtils.applyPointDensity(props.datasetList, props.userInfo.point_cloud_density);
		mapUtils.applyMeasureUnits(props.userInfo.measurement_units);
		props.setLabelProperty(props.userInfo.label_property);
	}, [props.userInfo]);

	useEffect(() => {
		if (autoCreateAnnotation) {
			createAnnotationOnServer("Untitled " + window.typeMeasurement, "");
			setAutoCreateAnnotation(false);
		}
	}, [autoCreateAnnotation]);

	function isInListBySearch(file, annotationList, imageSearch) {
		if (!imageSearch || imageSearch === "") return true;
		let annotationNameSearch = false;
		if (annotationList) {
			let filterdAnnotation = annotationList.filter(function(value) {
				return value.file_id + "" === file.id + "" && value.name.toLowerCase().indexOf(imageSearch.toLowerCase()) !== -1;
			});

			if (filterdAnnotation.length > 0) annotationNameSearch = true;
		}

		return file.name.toLowerCase().indexOf(imageSearch.toLowerCase()) !== -1 || annotationNameSearch;
	}

	function hasAnnotation(file, annotationList, filterTypeArrays, filterStatusArrays, filterImageDate) {
		if (!filterTypeArrays) {
			filterTypeArrays = annotationTypeList.map((annotationType) => annotationType.value);
		}
		if (!filterStatusArrays) {
			filterStatusArrays = annotationStatusList.map((annotationStatus) => annotationStatus.value);
			filterStatusArrays.push("not_annotated");
		}
		if (!annotationList || !filterStatusArrays) {
			if (filterStatusArrays) {
				return filterStatusArrays.indexOf("not_annotated") >= 0;
			}
			return true;
		}
		if (filterImageDate) {
			if (filterImageDate.start_date || filterImageDate.end_date) {
				if (filterImageDate.filter_option === "annotation") {
					let filterdAnnotation = annotationList.filter(function(value) {
						return value.file_id + "" === file.id + "" 
							&& (!filterImageDate.start_date || (new Date(value.updated_at.substring(0, 10))).getTime() >= filterImageDate.start_date)
							&& (!filterImageDate.end_date || (new Date(value.updated_at.substring(0, 10))).getTime() <= filterImageDate.end_date);
					});
	
					if (filterdAnnotation.length === 0) return false;
				}
				else if (filterImageDate.filter_option === "image") {
					let locationInfo = mapUtils.getImageLocation(file.location);
					if (locationInfo && locationInfo.date) {
						if (filterImageDate.start_date) {
							if (new Date(locationInfo.date.substring(0, 10).replaceAll(":", "-")).getTime() < filterImageDate.start_date) return false;
						}
						if (filterImageDate.end_date) {
							if (new Date(locationInfo.date.substring(0, 10).replaceAll(":", "-")).getTime() > filterImageDate.end_date) return false;
						}
					}
				}
			}
		}
		
		let filterdAnnotation = annotationList.filter(function(value) {
			return value.file_id + "" === file.id + "";
		});

		if (filterdAnnotation.length === 0) return filterStatusArrays.indexOf("not_annotated") >= 0;

		return filterdAnnotation.filter(function(value) {
			return filterTypeArrays.indexOf(value.annotation_type) >= 0 && filterStatusArrays.indexOf(value.annotation_status) >= 0;
		}).length > 0;
	}

	function resetAndLoadProjectData() {
		if (!window.MAIN_CESIUM_VIEWER) {
			return;
		}

		let fileIDList = [];

		if (!window.loadedObjects) {
			window.loadedObjects = new Map();
		}

		props.datasetList.forEach(function(dataset) {
			if (dataset.item.status === "finish") {
				if (dataset.item.datatype === "img" || dataset.item.datatype === "kml" || dataset.item.datatype === "Report" 
				|| (dataset.item.datatype === "dem" && dataset.item.is_report)
				|| (!dataset.item.merge && dataset.item.datatype === "pointcloud")
				|| (!dataset.item.merge && dataset.item.datatype === "tiff")) {
					for (let i = 0; i < dataset.files.length; i ++) {
						fileIDList.push(dataset.item.datatype + "_" + dataset.files[i].id);
					}
				}
				else {
					if (dataset.item.datatype === "dem") {
						fileIDList.push(dataset.item.datatype + "_" + dataset.item.id + "_heightmap");
						fileIDList.push(dataset.item.datatype + "_" + dataset.item.id + "_hillshade");
					}
					else {
						fileIDList.push(dataset.item.datatype + "_" + dataset.item.id);
					}
				}
			}
		});
		mapUtils.removeUnnecessaryObjects(fileIDList);
		if (props.selectedProject && props.selectedProject.annotations) {
			mapUtils.drawAnnotations(props.selectedProject.annotations, props.datasetList, props.userInfo.measurement_units);
		}
		let changedTerrain = false;
		props.datasetList.forEach(function(dataset) {
			if (dataset.item.datatype === "pointcloud" && dataset.item.status === "finish") {
				if (!dataset.item.merge) {
					for (let i = 0; i < dataset.files.length; i ++) {
						let file = dataset.files[i];
						if (file.id >= 0 && window.loadedObjects.get(dataset.item.datatype + "_" + file.id)) {
							let item = window.loadedObjects.get(dataset.item.datatype + "_" + file.id);
							item.object.show = dataset.visible;
						}
						else if (file.id >= 0 && !window.loadedObjects.get(dataset.item.datatype + "_" + file.id)) {
							let url = mapUtils.getSubPath(dataset.item) + "/process/" + dataset.item.id + "/" + file.id + "/tileset.json"
							let tile3D = window.MAIN_CESIUM_VIEWER.scene.primitives.add(
								new Cesium.Cesium3DTileset({
									url: url,
								}),
							);
							tile3D.show = dataset.visible;

							window.loadedObjects.set(dataset.item.datatype + "_" + file.id, {
								datatype : dataset.item.datatype,
								object : tile3D
							});
							
							tile3D.readyPromise
							.then(() => {
								mapUtils.applyTilesetStyle(tile3D, dataset);
								mapUtils.applyPointDensityForTileset(tile3D, props.userInfo.point_cloud_density);
								mapUtils.matchLayer(window.MAIN_CESIUM_VIEWER, tile3D, dataset);
							});
						}
					}
				}
				else {
					if (window.loadedObjects.get(dataset.item.datatype + "_" + dataset.item.id)) {
						let item = window.loadedObjects.get(dataset.item.datatype + "_" + dataset.item.id);
						item.object.show = dataset.visible;
					}
					else {
						let url = mapUtils.getSubPath(dataset.item) + "/process/" + dataset.item.id + "/tileset.json"
						let tile3D = window.MAIN_CESIUM_VIEWER.scene.primitives.add(
							new Cesium.Cesium3DTileset({
								url: url,
							}),
						);
						tile3D.show = dataset.visible;

						window.loadedObjects.set(dataset.item.datatype + "_" + dataset.item.id, {
							datatype : dataset.item.datatype,
							object : tile3D
						});

						tile3D.readyPromise
						.then(() => {
							mapUtils.applyTilesetStyle(tile3D, dataset);
							mapUtils.applyPointDensityForTileset(tile3D, props.userInfo.point_cloud_density);
							mapUtils.matchLayer(window.MAIN_CESIUM_VIEWER, tile3D, dataset);
						});
					}
				}
			}
			else if (dataset.item.datatype === "tiff" && dataset.item.status === "finish") {
				if (!dataset.item.merge) {
					for (let i = 0; i < dataset.files.length; i ++) {
						let file = dataset.files[i];
						if (file.id >= 0 && window.loadedObjects.get(dataset.item.datatype + "_" + file.id)) {
							let item = window.loadedObjects.get(dataset.item.datatype + "_" + file.id);
							item.object.show = dataset.visible;
						}
						else if (file.id >= 0 && !window.loadedObjects.get(dataset.item.datatype + "_" + file.id)) {
							let layer = window.MAIN_CESIUM_VIEWER.imageryLayers.addImageryProvider(new Cesium.TileMapServiceImageryProvider({
								url: mapUtils.getSubPath(dataset.item) + "/process/" + dataset.item.id + "/" + file.id
							}));
							layer.show = dataset.visible;
							window.loadedObjects.set(dataset.item.datatype + "_" + file.id, {
								datatype : dataset.item.datatype,
								object : layer,
								file: file
							});
						}
					}
				}
				else {
					if (window.loadedObjects.get(dataset.item.datatype + "_" + dataset.item.id)) {
						let item = window.loadedObjects.get(dataset.item.datatype + "_" + dataset.item.id);
						item.object.show = dataset.visible;
					}
					else {
						let layer = window.MAIN_CESIUM_VIEWER.imageryLayers.addImageryProvider(new Cesium.TileMapServiceImageryProvider({
							url: mapUtils.getSubPath(dataset.item) + "/process/" + dataset.item.id
						}));
						layer.show = dataset.visible;
						window.loadedObjects.set(dataset.item.datatype + "_" + dataset.item.id, {
							datatype : dataset.item.datatype,
							object : layer,
						});
					}
				}
			}
			else if (dataset.item.datatype === "dem" && dataset.item.status === "finish") {
				if (!dataset.item.is_report) {
					if (window.loadedObjects.get(dataset.item.datatype + "_" + dataset.item.id + "_heightmap")) {
						let item = window.loadedObjects.get(dataset.item.datatype + "_" + dataset.item.id + "_heightmap");
						if (item.object.materialType) {
							item.object.show = dataset.visible && dataset.materialMode === item.object.materialType;	
						}
						else {
							item.object.show = dataset.visible;
						}
					}
					else {
						let layer = window.MAIN_CESIUM_VIEWER.imageryLayers.addImageryProvider(new Cesium.TileMapServiceImageryProvider({
							url: mapUtils.getSubPath(dataset.item) + "/process/" + dataset.item.id + "/" + dataset.item.id + "_heightmap"
						}));
						layer.show = dataset.visible && dataset.materialMode === "rgba";
						layer.materialType = "rgba";
						window.loadedObjects.set(dataset.item.datatype + "_" + dataset.item.id + "_heightmap", {
							datatype : dataset.item.datatype,
							object : layer,
						});
					}

					if (window.loadedObjects.get(dataset.item.datatype + "_" + dataset.item.id + "_hillshade")) {
						let item = window.loadedObjects.get(dataset.item.datatype + "_" + dataset.item.id + "_hillshade");
						if (item.object.materialType) {
							item.object.show = dataset.visible && dataset.materialMode === item.object.materialType;	
						}
						else {
							item.object.show = dataset.visible;
						}
					}
					else {
						let layer = window.MAIN_CESIUM_VIEWER.imageryLayers.addImageryProvider(new Cesium.TileMapServiceImageryProvider({
							url: mapUtils.getSubPath(dataset.item) + "/process/" + dataset.item.id + "/" + dataset.item.id + "_hillshade"
						}));
						layer.show = dataset.visible && dataset.materialMode === "hillshade";
						layer.materialType = "hillshade";
						window.loadedObjects.set(dataset.item.datatype + "_" + dataset.item.id + "_hillshade", {
							datatype : dataset.item.datatype,
							object : layer,
						});
					}
				}
				else {
					for (let i = 0; i < dataset.files.length; i ++) {
						let file = dataset.files[i];
						if (file.id >= 0 && window.loadedObjects.get(dataset.item.datatype + "_" + file.id)) {
							let item = window.loadedObjects.get(dataset.item.datatype + "_" + file.id);
							if (item.object instanceof Cesium.ImageryLayer) {
								if (item.object.materialType) {
									item.object.show = dataset.visible && dataset.materialMode === item.object.materialType;	
								}
								else {
									item.object.show = dataset.visible;
								}
							}
							else {
								if (dataset.enableTerrain && dataset.visible) {
									window.MAIN_CESIUM_VIEWER.terrainProvider = item.object;
									changedTerrain = true;
								}
							}
						}
						else if (file.id >= 0 && !window.loadedObjects.get(dataset.item.datatype + "_" + file.id)) {
							let layer;
							if (file.name && file.name === mapUtils.normalizeName(dataset.item.name) + "_heightmap.tif") {
								layer = window.MAIN_CESIUM_VIEWER.imageryLayers.addImageryProvider(new Cesium.TileMapServiceImageryProvider({
									url: mapUtils.getSubPath(dataset.item) + "/process/" + dataset.item.id + "/" + file.name
								}));
								layer.show = dataset.visible && dataset.materialMode === "rgba";
								layer.materialType = "rgba";
							}
							else if (file.name && file.name === mapUtils.normalizeName(dataset.item.name) + "_hillshade.tif") {
								layer = window.MAIN_CESIUM_VIEWER.imageryLayers.addImageryProvider(new Cesium.TileMapServiceImageryProvider({
									url: mapUtils.getSubPath(dataset.item) + "/process/" + dataset.item.id + "/" + file.name
								}));
								layer.show = dataset.visible && dataset.materialMode === "hillshade";
								layer.materialType = "hillshade";
							}
							// else if (file.name && file.name === mapUtils.normalizeName(dataset.item.name) + ".tif") {
							// 	layer = new Cesium.CesiumTerrainProvider({
							// 		url :  mapUtils.getSubPath(dataset.item) + "/process/" + dataset.item.id + "/" + file.name
							// 	})
	
							// 	if (dataset.enableTerrain && dataset.visible) {
							// 		window.MAIN_CESIUM_VIEWER.terrainProvider = layer;
							// 		changedTerrain = true;
							// 	}
							// }
	
							if (layer) {
								window.loadedObjects.set(dataset.item.datatype + "_" + file.id, {
									datatype : dataset.item.datatype,
									object : layer,
									file: file
								});
							}
						}
					}
				}
			}
			else if (dataset.item.datatype === "img" && dataset.item.status === "finish") {
				let filterImage = dataset.filterImage;
				let filterImageAnnotation = dataset.filterImageAnnotation;
				let filterImageAnnotationStatus = dataset.filterImageAnnotationStatus;
				let filterImageSearch = dataset.filterImageSearch;
				let filterImageDate = dataset.filterImageDate;
				for (let i = 0; i < dataset.files.length; i ++) {
					let file = dataset.files[i];
					if (file.id >= 0 && window.loadedObjects.get(dataset.item.datatype + "_" + file.id)) {
						let item = window.loadedObjects.get(dataset.item.datatype + "_" + file.id);
						item.object.show = dataset.visible && ((filterImage)?filterImage.indexOf(file.inspect) >= 0:true) && isInListBySearch(file, dataset.annotationList, filterImageSearch) && hasAnnotation(file, dataset.annotationList, filterImageAnnotation, filterImageAnnotationStatus, filterImageDate);

						if (item.object._label) {
							item.object._label.show = dataset.showLabel
						}
						if (item.object._point) {
							item.object._point.color = Cesium.Color.fromCssColorString(mapUtils.getInspectColor(file.inspect)).withAlpha(0.8);
						}
					}
					else if (file.id >= 0 && !window.loadedObjects.get(dataset.item.datatype + "_" + file.id)) {
						let image_location = mapUtils.getImageLocation(file.location);
						let longitude = image_location.longitude;
						let latitude = image_location.latitude;
						let altitude = image_location.altitude;
						if (!longitude || isNaN(longitude)) longitude = 0;
						if (!latitude || isNaN(latitude)) latitude = 0;
						if (!altitude || isNaN(altitude)) altitude = 0;

						if (!isNaN(longitude) && !isNaN(latitude) && !isNaN(altitude)) {
							let imagePoint = window.MAIN_CESIUM_VIEWER.entities.add({
								position: Cesium.Cartesian3.fromDegrees(
									longitude,
									latitude,
									altitude
								),
								point: {
									pixelSize: 20,
									// color: Cesium.Color.fromCssColorString("#FF0000").withAlpha(0.8),
									color: Cesium.Color.fromCssColorString(mapUtils.getInspectColor(file.inspect)).withAlpha(0.8),
									heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
								},
								label: {
									text: file.name,
									font: "22px Helvetica",
									fillColor: Cesium.Color.BLACK,
									outlineColor: Cesium.Color.WHITE,
									outlineWidth: 3,
									style: Cesium.LabelStyle.FILL_AND_OUTLINE,
									horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
									pixelOffset : new Cesium.Cartesian2(20, 0),
									show: dataset.showLabel,
									heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
								},
								id: "image_item_" + file.id,
							});

							imagePoint.project = props.selectedProject.project;
							imagePoint.dataset = dataset.item;
							imagePoint.file = file;
							imagePoint.show = dataset.visible && ((filterImage)?filterImage.indexOf(file.inspect) >= 0:true) && isInListBySearch(file, dataset.annotationList, filterImageSearch) && hasAnnotation(file, dataset.annotationList, filterImageAnnotation, filterImageAnnotationStatus, filterImageDate);

							window.loadedObjects.set(dataset.item.datatype + "_" + file.id, {
								datatype : dataset.item.datatype,
								object : imagePoint,
								file : file,
								location : [longitude, latitude, altitude]
							});
						}
					}
				}
			}
			else if (dataset.item.datatype === "kml" && dataset.item.status === "finish") {
				for (let i = 0; i < dataset.files.length; i ++) {
					let file = dataset.files[i];
					let labelProperty = dataset.labelProperty;
					let item = window.loadedObjects.get(dataset.item.datatype + "_" + file.id);
					if (file.id >= 0 && item && item.object instanceof Cesium.KmlDataSource) {
						item.object.show = dataset.visible;

						if (item.object) {
							let entities = item.object.entities.values;
							for (let i = 0; i < entities.length; i ++) {
								if (entities[i]._label) {
									if (labelProperty === "none") {
										entities[i]._label.show = false;
									}
									else {
										if (labelProperty === "default") {
											if (entities[i]._label.defaultText != "") {
												entities[i]._label.text = entities[i]._label.defaultText;
												entities[i]._label.show = true;
											}
											else {
												entities[i]._label.show = false;
											}
										}
										else {
											let labelText = mapUtils.getDescriptionValue(entities[i].description, labelProperty);
											if (labelText !== "") {
												entities[i]._label.text = labelText;
												entities[i]._label.show = true;
											}
											else {
												entities[i]._label.show = false;
											}
										}
									}
								}

								if (entities[i]._billboard) {
									entities[i]._billboard.image = '/images/pole' + props.userInfo.pole_icon + '.png';
									entities[i]._billboard.color = Cesium.Color.fromCssColorString(props.userInfo.style_color).withAlpha(1)
								}
							}
						}
					}
					else if (file.id >= 0 && !item) {
						let url = mapUtils.getSubPath(dataset.item) + "/datasets/" + dataset.item.id + "/" + file.name;
						let kmlDatasource = Cesium.KmlDataSource.load(url, {
							camera: window.MAIN_CESIUM_VIEWER.scene.camera,
							canvas: window.MAIN_CESIUM_VIEWER.scene.canvas,
							clampToGround: true,
						});
						window.loadedObjects.set(dataset.item.datatype + "_" + file.id, {
							datatype : dataset.item.datatype,
							object : kmlDatasource,
							file : file
						});
						
						window.MAIN_CESIUM_VIEWER.dataSources.add(kmlDatasource).then(function (dataSource) {
							let entities = dataSource.entities.values;
							for (let i = 0; i < entities.length; i ++) {
								if (entities[i]._label && entities[i]._label.text) {
									entities[i]._label.fillColor = Cesium.Color.BLACK;
									entities[i]._label.outlineColor = Cesium.Color.WHITE;
									entities[i]._label.outlineWidth = 3;
									entities[i]._label.style = Cesium.LabelStyle.FILL_AND_OUTLINE;
									entities[i]._label.font = "22px Helvetica";
									entities[i]._label.horizontalOrigin = Cesium.HorizontalOrigin.CENTER;
									entities[i]._label.verticalOrigin = Cesium.VerticalOrigin.BOTTOM;
									entities[i]._label.pixelOffset = new Cesium.Cartesian2(0, -60);
									entities[i]._label.scale = undefined;
									entities[i]._label.scaleByDistance = undefined;
									entities[i]._label.defaultText = entities[i]._label.text._value;
								}
								else {
									entities[i].label = new Cesium.LabelGraphics({
										fillColor : Cesium.Color.BLACK,
										outlineColor: Cesium.Color.WHITE,
										outlineWidth: 3,
										style: Cesium.LabelStyle.FILL_AND_OUTLINE,
										font: "22px Helvetica",
										horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
										verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
										pixelOffset: new Cesium.Cartesian2(0, -60),
										scale: undefined,
										scaleByDistance: undefined,
										text: ""
									});
									entities[i]._label.defaultText = "";
									entities[i]._label.show = false;
								}

								if (entities[i]._label) {
									if (labelProperty === "none") {
										entities[i]._label.show = false;
									}
									else {
										if (labelProperty === "default") {
											if (entities[i]._label.defaultText != "") {
												entities[i]._label.text = entities[i]._label.defaultText;
												entities[i]._label.show = true;
											}
											else {
												entities[i]._label.show = false;
											}
										}
										else {
											let labelText = mapUtils.getDescriptionValue(entities[i].description, labelProperty);
											if (labelText !== "") {
												entities[i]._label.text = labelText;
												entities[i]._label.show = true;
											}
											else {
												entities[i]._label.show = false;
											}
										}
									}
								}

								if (entities[i]._billboard) {
									// entities[i]._billboard.image = Cesium.buildModuleUrl("Assets/Textures/maki/oil-well.png");
									entities[i]._billboard.image = '/images/pole' + props.userInfo.pole_icon + '.png';
									entities[i]._billboard.color = Cesium.Color.fromCssColorString(props.userInfo.style_color).withAlpha(1)
									entities[i]._billboard.verticalOrigin = Cesium.VerticalOrigin.BOTTOM;
									entities[i]._billboard.scale = undefined;
									entities[i]._billboard.width = 40;
									entities[i]._billboard.height = 40;
									entities[i]._billboard.scaleByDistance = new Cesium.NearFarScalar(2000000, 1, 16000000, 0.1);
								}
							}
							dataSource.show = dataset.visible;

							window.loadedObjects.set(dataset.item.datatype + "_" + file.id, {
								datatype : dataset.item.datatype,
								object : dataSource,
								file : file
							});
						});
					}
				}
			}
			else if (dataset.item.datatype === "Report" && dataset.item.status === "finish") {
				let reportFiles = (dataset.item.reportFiles)?JSON.parse(dataset.item.reportFiles):(dataset.item.report_type === "image")?[0, 0, 0, 0, 0, 0, 0, 0, 0, 0]:(dataset.item.report_type === "image")?[0, 0, 0, 0, 0, 0]:[0, 0, 0, 0, 0, 0, 0, 0];
				for (let i = 0; i < dataset.files.length; i ++) {
					let file = dataset.files[i];
					if (file.name.endsWith(".las")) {
						let visible = dataset.visible && (reportFiles.length > 4?Boolean(reportFiles[4]):false);
						if (file.id >= 0 && window.loadedObjects.get(dataset.item.datatype + "_" + file.id)) {
							let item = window.loadedObjects.get(dataset.item.datatype + "_" + file.id);
							item.object.show = visible;
						}
						else if (file.id >= 0 && !window.loadedObjects.get(dataset.item.datatype + "_" + file.id)) {
							let url = mapUtils.getSubPath(dataset.item) + "/process/" + dataset.item.id + "/" + file.id + "/tileset.json"
							let tile3D = window.MAIN_CESIUM_VIEWER.scene.primitives.add(
								new Cesium.Cesium3DTileset({
									url: url,
								}),
							);
							tile3D.show = visible;
	
							window.loadedObjects.set(dataset.item.datatype + "_" + file.id, {
								datatype : dataset.item.datatype,
								object : tile3D
							});
							
							tile3D.readyPromise
							.then(() => {
								mapUtils.applyTilesetStyle(tile3D, dataset);
								mapUtils.applyPointDensityForTileset(tile3D, props.userInfo.point_cloud_density);
								mapUtils.matchLayer(window.MAIN_CESIUM_VIEWER, tile3D, dataset);
							});
						}
					}
					else if (file.name.endsWith(".kmz")) {
						let visible = true;
						if (dataset.item.report_type === "image") {
							visible = dataset.visible && (reportFiles.length > 5?Boolean(reportFiles[5]):false);
						}
						else if (dataset.item.report_type === "map") {
							visible = dataset.visible && (reportFiles.length > 3?Boolean(reportFiles[3]):false);
						}
						else {
							visible = dataset.visible && (reportFiles.length > 7?Boolean(reportFiles[7]):false);
						}
						let item = window.loadedObjects.get(dataset.item.datatype + "_" + file.id);
						if (file.id >= 0 && item && item.object instanceof Cesium.KmlDataSource) {
							item.object.show = visible;
	
							if (item.object) {
								let entities = item.object.entities.values;
								for (let i = 0; i < entities.length; i ++) {
									if (entities[i]._billboard) {
										entities[i]._billboard.image = api.serverPath + ((dataset.item.report_type === "image")?"/globemap/white_circle.png":"/globemap/white_marker.png");
									}
								}
							}
						}
						else if (file.id >= 0 && !item) {
							let url = mapUtils.getSubPath(dataset.item) + "/datasets/" + dataset.item.id + "/" + file.name;
							let kmlDatasource = Cesium.KmlDataSource.load(url, {
								camera: window.MAIN_CESIUM_VIEWER.scene.camera,
								canvas: window.MAIN_CESIUM_VIEWER.scene.canvas,
								clampToGround: true,
							});
							window.loadedObjects.set(dataset.item.datatype + "_" + file.id, {
								datatype : dataset.item.datatype,
								object : kmlDatasource,
								file : file
							});
							
							window.MAIN_CESIUM_VIEWER.dataSources.add(kmlDatasource).then(function (dataSource) {
								let entities = dataSource.entities.values;
								for (let i = 0; i < entities.length; i ++) {
									if (entities[i]._label && entities[i]._label.text) {
										entities[i]._label.fillColor = Cesium.Color.BLACK;
										entities[i]._label.outlineColor = Cesium.Color.WHITE;
										entities[i]._label.outlineWidth = 3;
										entities[i]._label.style = Cesium.LabelStyle.FILL_AND_OUTLINE;
										entities[i]._label.font = "22px Helvetica";
										entities[i]._label.horizontalOrigin = Cesium.HorizontalOrigin.CENTER;
										entities[i]._label.verticalOrigin = Cesium.VerticalOrigin.BOTTOM;
										entities[i]._label.pixelOffset = new Cesium.Cartesian2(0, -40);
										entities[i]._label.scale = undefined;
										entities[i]._label.scaleByDistance = undefined;
										entities[i]._label.defaultText = entities[i]._label.text._value;
									}
	
									if (entities[i]._billboard) {
										entities[i]._billboard.image = api.serverPath + ((dataset.item.report_type === "image")?"/globemap/white_circle.png":"/globemap/white_marker.png");
										entities[i]._billboard.verticalOrigin = Cesium.VerticalOrigin.CENTER;
										entities[i]._billboard.scale = undefined;
										entities[i]._billboard.width = 30;
										entities[i]._billboard.height = 30;
										entities[i]._billboard.scaleByDistance = new Cesium.NearFarScalar(2000000, 1, 16000000, 0.1);
									}
									if (dataset.item.report_type === "image" && entities[i].description && entities[i].description._value
									!= "") {
										let descriptionValue = mapUtils.extractContent(entities[i].description._value);
										if (descriptionValue && descriptionValue != "") {
											let parts = descriptionValue.split(":");
											if (parts.length === 3) {
												entities[i].report_type = "report_image_item";
												entities[i].project = props.selectedProject.project;
												entities[i].dataset = props.selectedProject.datasets.find(value => value.id + "" === parts[1]);
												entities[i].file = props.selectedProject.files.find(value => value.id + "" === parts[0]);
											}
										}
									}
								}
								dataSource.show = visible;
								window.loadedObjects.set(dataset.item.datatype + "_" + file.id, {
									datatype : dataset.item.datatype,
									object : dataSource,
									file : file
								});
							});
						}
					}
				}
			}
		});

		if (!changedTerrain && window.MAIN_CESIUM_VIEWER.terrainProvider._scheme !== "tms") {
			window.MAIN_CESIUM_VIEWER.terrainProvider = Cesium.createWorldTerrain({
				requestWaterMask: true,
				requestVertexNormals: true
			});
		}

		mapUtils.applyAllTilesetStyle(props.datasetList);
		mapUtils.applyPointDensity(props.datasetList, props.userInfo.point_cloud_density);
		mapUtils.applyMeasureUnits(props.userInfo.measurement_units);
	}

	function resetAndLoadAllAssets() {
		if (!window.MAIN_CESIUM_VIEWER) {
			return;
		}

		if (!window.loadedAssets) {
			window.loadedAssets = new Map();
		}

		window.loadedAssets.forEach(function(loadedAsset, key) {
			let index  = props.allAssets.findIndex( asset => asset.id + "" === loadedAsset.id + "" );
			if (index < 0) {
				window.MAIN_CESIUM_VIEWER.entities.remove(loadedAsset.object);
				window.loadedAssets.delete(key);
			}
		});

		// Asset Load Disable by Almond
		// props.allAssets.forEach(function(asset) {
		// 	let currentLabel = asset.name;
			
		// 	if (props.labelProperty === "none") {
		// 		currentLabel = "";
		// 	}
		// 	else if (props.labelProperty !== "default") {
		// 		let labelText = mapUtils.getDescriptionValue(asset.detail, props.labelProperty);
		// 		if (labelText !== "") {
		// 			currentLabel = labelText;3
		// 		}
		// 	}

		// 	let addedAssetObject = window.loadedAssets.get(asset.id);
		// 	if (addedAssetObject) {
		// 		addedAssetObject.object.show = Boolean(asset.visible);
		// 		addedAssetObject.object._label.text = currentLabel;
		// 		addedAssetObject.object._billboard.image = '/images/pole' + props.userInfo.pole_icon + '.png';
		// 		addedAssetObject.object._billboard.color = Cesium.Color.fromCssColorString(props.userInfo.style_color).withAlpha(1);
		// 	}
		// 	else {
		// 		let positions = JSON.parse(asset.positions);
		// 		let object = window.MAIN_CESIUM_VIEWER.entities.add({
		// 			position: Cesium.Cartesian3.fromRadians(positions[0], positions[1], positions[2]),
		// 			billboard: {
		// 				image: '/images/pole' + props.userInfo.pole_icon + '.png',
		// 				color: Cesium.Color.fromCssColorString(props.userInfo.style_color).withAlpha(1),
		// 				horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
		// 				verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
		// 				width: 50,
		// 				height: 50,
		// 				scaleByDistance: new Cesium.NearFarScalar(2000000, 1, 16000000, 0),
		// 				disableDepthTestDistance: Number.POSITIVE_INFINITY,
		// 				heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
		// 			},
		// 			label: {
		// 				fillColor : Cesium.Color.BLACK,
		// 				outlineColor: Cesium.Color.WHITE,
		// 				outlineWidth: 3,
		// 				style: Cesium.LabelStyle.FILL_AND_OUTLINE,
		// 				font: "22px Helvetica",
		// 				horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
		// 				verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
		// 				pixelOffset: new Cesium.Cartesian2(0, -60),
		// 				scaleByDistance: new Cesium.NearFarScalar(2000000, 1, 16000000, 0),
		// 				text: currentLabel,
		// 				heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
		// 				show: props.labelProperty !== "none"
		// 			},
		// 			show: Boolean(asset.visible)
		// 		});

		// 		window.loadedAssets.set(asset.id, {
		// 			object: object
		// 		})
		// 	}
		// });
	}

	const flags = {
		looking: false,
		moveUp: false,
		moveDown: false,
		moveLeft: false,
		moveRight: false,
		rotateClockWise: false,
		rotateCounterClockWise: false,
		tiltUp: false,
		tiltDown: false,
		zoomIn: false,
		zoomOut: false,
		resetNothUp: false,
		resetTopDownTilt: false,
		centerEarth: false,
		isKeyDown: false,
	};

	function resetKeyFlags() {
		for (let key in flags) {
			flags[key] = false;
		} 
	}

	function keyDownListener(event) {
		var flagName = getFlagForKeyCode(event);
		if (typeof flagName !== 'undefined') {
			flags[flagName] = true;
			flags.isKeyDown = true;
		}
	}

	function keyUpListener(event) {
		var flagName = getFlagForKeyCode(event);
		if (typeof flagName !== 'undefined') {
			flags[flagName] = false;
		}
		flags.isKeyDown = false;
	}

	function getFlagForKeyCode(event) {
		if (document.activeElement.tagName === "INPUT" || document.activeElement.tagName === "TEXTAREA" 
			|| document.body.classList.contains('modal-open')) return undefined;
		if (event.shiftKey && event.keyCode === 37) { // shift + left
			return 'rotateClockWise';
		}
		else if (event.shiftKey && event.keyCode === 39) { // shift + right
			return 'rotateCounterClockWise';
		}
		else if (event.shiftKey && event.keyCode === 40) { // shift + down
			return 'tiltDown';
		}
		else if (event.shiftKey && event.keyCode === 38) { // shift + up
			return 'tiltUp';
		}
		else if (event.keyCode === 109) { // key: -
			return 'zoomOut';
		}
		else if (event.keyCode === 107) { // key: +
			return 'zoomIn';
		}
		else if (event.keyCode === 78) { // key: N
			return 'resetNothUp';
		}
		else if (event.keyCode === 85) { // key: U
			return 'resetTopDownTilt';
		}
		else if (event.keyCode === 82) { // key: R
			return 'centerEarth';
		}
		
		switch (event.keyCode) {
			case 38: // Key Up
				return 'moveUp';
			case 40: // Key Down
				return 'moveDown';
			case 39: // Key Right
				return 'moveRight';
			case 37: // Key Left
				return 'moveLeft';
		}

		return undefined;
	}

	function tickEvent(clock) {
		if (!flags.isKeyDown || !window.MAIN_CESIUM_VIEWER) return;
		const camera = window.MAIN_CESIUM_VIEWER.camera;
		const canvas = window.MAIN_CESIUM_VIEWER.canvas;
		const ellipsoid = window.MAIN_CESIUM_VIEWER.scene.globe.ellipsoid;

		// Change movement speed based on the distance of the camera to the surface of the ellipsoid.
		const cameraHeight = ellipsoid.cartesianToCartographic(
			camera.position
		).height;
		const moveRate = cameraHeight / 100.0;
		let cameraHeading = camera.heading;
		let cameraTilt = camera.pitch;
		let cameraRoll = camera.roll;
		if (flags.zoomIn) {
			camera.moveForward(moveRate);
		}
		if (flags.zoomOut) {
			camera.moveBackward(moveRate);
		}
		if (flags.moveUp) {
			if (Math.abs(Math.abs(cameraTilt) - Math.PI / 2) < 0.01) {
				camera.moveUp(moveRate);
			}
			else {
				let nextPosition = new Cesium.Cartesian3();
				nextPosition = Cesium.Cartesian3.add(camera.position, camera.directionWC, nextPosition);
				let nextPositionCarto = ellipsoid.cartesianToCartographic(nextPosition);
				nextPositionCarto.height = cameraHeight;
				nextPosition = ellipsoid.cartographicToCartesian(nextPositionCarto);
				nextPosition = Cesium.Cartesian3.subtract(nextPosition, camera.position, nextPosition);
				nextPosition = Cesium.Cartesian3.normalize(nextPosition, nextPosition);
				camera.move(nextPosition, moveRate);
			}
		}
		if (flags.moveDown) {
			if (Math.abs(Math.abs(cameraTilt) - Math.PI / 2) < 0.01) {
				camera.moveDown(moveRate);
			}
			else {
				let nextPosition = new Cesium.Cartesian3();
				nextPosition = Cesium.Cartesian3.subtract(camera.position, camera.directionWC, nextPosition);
				let nextPositionCarto = ellipsoid.cartesianToCartographic(nextPosition);
				nextPositionCarto.height = cameraHeight;
				nextPosition = ellipsoid.cartographicToCartesian(nextPositionCarto);
				nextPosition = Cesium.Cartesian3.subtract(nextPosition, camera.position, nextPosition);
				nextPosition = Cesium.Cartesian3.normalize(nextPosition, nextPosition);
				camera.move(nextPosition, moveRate);
			}
		}
		if (flags.moveLeft) {
			camera.moveLeft(moveRate);
		}
		if (flags.moveRight) {
			camera.moveRight(moveRate);
		}
		if (flags.rotateClockWise) {
			cameraHeading -= Math.PI / 360;
			camera.setView({
				orientation: {
					heading : cameraHeading,
					pitch : cameraTilt,
					roll : cameraRoll
				}
			});
		}
		if (flags.rotateCounterClockWise) {
			cameraHeading += Math.PI / 360;
			camera.setView({
				orientation: {
					heading : cameraHeading,
					pitch : cameraTilt,
					roll : cameraRoll
				}
			});
		}
		if (flags.tiltUp) {
			cameraTilt -= Math.PI / 360;
			if (cameraTilt < -Math.PI / 2) return;
			camera.setView({
				orientation: {
					heading : cameraHeading,
					pitch : cameraTilt,
					roll : cameraRoll
				}
			});
		}
		if (flags.tiltDown) {
			cameraTilt += Math.PI / 360;
			if (cameraTilt > Math.PI / 2) return;
			camera.setView({
				orientation: {
					heading : cameraHeading,
					pitch : cameraTilt,
					roll : cameraRoll
				}
			});
		}
		if (flags.resetNothUp) {
			camera.setView({
				orientation: {
					heading : cameraHeading,
					pitch : - Math.PI / 2,
					roll : 0
				}
			});
		}
		if (flags.resetTopDownTilt) {
			camera.setView({
				orientation: {
					heading : cameraHeading,
					pitch : - Math.PI / 2,
					roll : cameraRoll
				}
			});
		}
		if (flags.centerEarth) {
			// camera.flyHome(1.0);

			var destination;
			var orientation = {
				heading: 0,
				pitch: -Math.PI / 2,
				roll: 0,
			};
			var original = Cesium.Ellipsoid.WGS84.cartesianToCartographic(camera.position);
		
			var rayScratch = new Cesium.Ray();
			rayScratch.origin = camera.positionWC;
			rayScratch.direction = camera.directionWC;
			var focus = new Cesium.Cartesian3();
			focus = window.MAIN_CESIUM_VIEWER.scene.globe.pick(rayScratch, window.MAIN_CESIUM_VIEWER.scene, focus);
			if (focus === undefined) {
				destination = camera.position;
			} else {
				var targetFocus = Cesium.Ellipsoid.WGS84.cartesianToCartographic(focus);
				let distance = Cesium.Cartesian3.distance(camera.position, focus)
				let height = window.MAIN_CESIUM_VIEWER.scene.globe.getHeight(targetFocus);
				if (!height) {
					height = 0;
				}
				destination = Cesium.Cartesian3.fromRadians(targetFocus.longitude, targetFocus.latitude, distance + height);
			}

			camera.flyTo({
				destination: destination,
				orientation: orientation,
				duration: 1.5,
				convert: false,
			});
		}
	}

	useEffect(() => {
		if (window.map_longitude && window.map_latitude != "") {
			api.setParameter("longitude", window.map_longitude);
			api.setParameter("latitude", window.map_latitude);
			api.setParameter("altitude", window.map_altitude + 2000);
			
			window.map_longitude = "";
			window.map_latitude = "";
			window.map_altitude = "";
		}
		initViewer();
		setMouseEventHandlers();
		document.addEventListener('keydown', keyDownListener, false);
		document.addEventListener('keyup', keyUpListener, false);
		window.MAIN_CESIUM_VIEWER.clock.onTick.addEventListener(tickEvent);

		resetAndLoadProjectData();

		setFullScreen(isFullScreen());

		return () => {
			if (mouseHandler)
				mouseHandler.destroy();
			
			document.removeEventListener('keydown', keyDownListener);
			document.removeEventListener('keyup', keyUpListener);
			window.MAIN_CESIUM_VIEWER.clock.onTick.removeEventListener(tickEvent);
		}
	}, []);

	function _zoomInButtonClicked() {
		if (window.MAIN_CESIUM_VIEWER === null || window.MAIN_CESIUM_VIEWER === undefined)
			return;
		mapUtils.animationZoom(window.MAIN_CESIUM_VIEWER, 0.5);
	}

	function _zoomOutButtonClicked() {
		if (window.MAIN_CESIUM_VIEWER === null || window.MAIN_CESIUM_VIEWER === undefined)
			return;
		mapUtils.animationZoom(window.MAIN_CESIUM_VIEWER, 2);
	}

	function _reCenterButtonClicked() {
		if (window.MAIN_CESIUM_VIEWER) {
			mapUtils.cameraResetTilt(window.MAIN_CESIUM_VIEWER, window.MAIN_CESIUM_VIEWER.camera.heading, -Math.PI / 2, window.MAIN_CESIUM_VIEWER.camera.roll);
		}
	}

	function _toggle2DMap() {
		if (window.MAIN_CESIUM_VIEWER && !window.MAIN_CESIUM_VIEWER.is2D) {
			window.MAIN_CESIUM_VIEWER.is2D = true;
			mapUtils.cameraReset3D(window.MAIN_CESIUM_VIEWER, 0);
			mapUtils.enableTiltCesium(window.MAIN_CESIUM_VIEWER, false);
		}
	}

	function _toggle3DMap() {
		if (window.MAIN_CESIUM_VIEWER && window.MAIN_CESIUM_VIEWER.is2D) {
			window.MAIN_CESIUM_VIEWER.is2D = false;
			mapUtils.cameraReset3D(window.MAIN_CESIUM_VIEWER, 1);
			mapUtils.enableTiltCesium(window.MAIN_CESIUM_VIEWER, true);
		}
	}

	function handleMenuClose() {
		setAnchorPosition(null);
	}

	function startAddMarker() {
		if (!props.selectedProject || props.selectedProject.project.id === -1) {
			toast("Select Project first");
			return;
		}
		handleMenuClose();
		window.addingMeasurement = true;
		window.typeMeasurement = "marker";
		window.measurementMarker = undefined;
	}

	function startAddDistance() {
		if (!props.selectedProject || props.selectedProject.project.id === -1) {
			toast("Select Project first");
			return;
		}
		handleMenuClose();
		window.addingMeasurement = true;
		window.typeMeasurement = "distance";
		window.measurementPoints = undefined;
		window.measurementDistance = undefined;
		window.measurementLabel = undefined;
	}

	function startAddPolyline() {
		if (!props.selectedProject || props.selectedProject.project.id === -1) {
			toast("Select Project first");
			return;
		}
		handleMenuClose();
		window.addingMeasurement = true;
		window.typeMeasurement = "polyline";
		window.measurementPoints = undefined;
		window.measurementDistance = undefined;
		window.measurementLabel = undefined;
	}

	function onChangeAnnotationName(evt) {
        setCreatingAnnotationName(evt.target.value);
    }

    function onChangeAnnotationDescription(evt) {
        setCreatingAnnotationDescription(evt.target.value);
    }

    function hideAnnoationDialog() {
        setCreatingLidarAnnotation(false);
		resetCurrentAnnotation();
		setCreatingAnnotationName("");
		setCreatingAnnotationDescription("");
		setAnnotationStatus("0");
    }

    function createAnnotation() {
		if (creatingAnnotationName !== "") {
			createAnnotationOnServer(creatingAnnotationName, creatingAnnotationDescription);
        	setCreatingLidarAnnotation(false);
		}
    }

	function resetCurrentAnnotation() {
		if (window.typeMeasurement === "marker" && window.measurementMarker) {
			window.MAIN_CESIUM_VIEWER.entities.remove(window.measurementMarker);
			window.measurementMarker = undefined;
		}
		else if (window.typeMeasurement === "distance") {
			if (window.measurementPoints) {
				for (let i = 0; i < window.measurementPoints.length; i ++) {
					window.MAIN_CESIUM_VIEWER.entities.remove(window.measurementPoints[i]);
				}
			}
			if (window.measurementDistance) {
				window.MAIN_CESIUM_VIEWER.entities.remove(window.measurementDistance);
			}
			if (window.measurementLabel) {
				window.MAIN_CESIUM_VIEWER.entities.remove(window.measurementLabel);
			}
			window.measurementPoints = undefined;
			window.measurementLabel = undefined;
			window.measurementDistance = undefined;
		}
		else if (window.typeMeasurement === "polyline") {
			if (window.measurementPoints) {
				for (let i = 0; i < window.measurementPoints.length; i ++) {
					window.MAIN_CESIUM_VIEWER.entities.remove(window.measurementPoints[i]);
				}
			}
			if (window.measurementDistance) {
				for (let i = 0; i < window.measurementDistance.length; i ++) {
					window.MAIN_CESIUM_VIEWER.entities.remove(window.measurementDistance[i]);
				}
			}
			if (window.measurementLabel) {
				for (let i = 0; i < window.measurementLabel.length; i ++) {
					window.MAIN_CESIUM_VIEWER.entities.remove(window.measurementLabel[i]);
				}
			}
			window.measurementPoints = undefined;
			window.measurementLabel = undefined;
			window.measurementDistance = undefined;
		}
	}

	function redirectToLogin() {
        props.history.replace("/");
    }

	function processAnnotation(annotation) {
		if (!window.annotationList) window.annotationList = new Map();
		let id = annotation.id;
		if (window.typeMeasurement === "marker" && window.measurementMarker) {
			window.measurementMarker.dataset_id = annotation.dataset_id;
			window.annotationList.set(id, [window.measurementMarker]);
		}
		else if (window.typeMeasurement === "distance") {
			let entities = [];
			if (window.measurementPoints) {
				for (let i = 0; i < window.measurementPoints.length; i ++) {
					window.measurementPoints[i].dataset_id = annotation.dataset_id;
					entities.push(window.measurementPoints[i]);
				}
			}
			if (window.measurementDistance) {
				window.measurementDistance.dataset_id = annotation.dataset_id;
				entities.push(window.measurementDistance);
			}
			if (window.measurementLabel) {
				window.measurementLabel.dataset_id = annotation.dataset_id;
				entities.push(window.measurementLabel);
			}
			window.annotationList.set(id, entities);
		}
		else if (window.typeMeasurement === "polyline") {
			let entities = [];
			if (window.measurementPoints) {
				for (let i = 0; i < window.measurementPoints.length; i ++) {
					window.measurementPoints[i].dataset_id = annotation.dataset_id;
					entities.push(window.measurementPoints[i]);
				}
			}
			if (window.measurementDistance) {
				for (let i = 0; i < window.measurementDistance.length; i ++) {
					window.measurementDistance[i].dataset_id = annotation.dataset_id;
					entities.push(window.measurementDistance[i]);
				}
			}
			if (window.measurementLabel) {
				for (let i = 0; i < window.measurementLabel.length; i ++) {
					window.measurementLabel[i].dataset_id = annotation.dataset_id;
					entities.push(window.measurementLabel[i]);
				}
			}
			window.annotationList.set(id, entities);
		}
		let cloneProject = JSON.parse(JSON.stringify(props.selectedProject));
		if (cloneProject.project.id >= 0) {
			cloneProject.annotations.push(annotation);
			props.selectProject(cloneProject);
		}
	}

	async function createAnnotationOnServer(name, description) {
		let userToken = localStorage.getItem("userToken");
		if (api.isSharing()) {
			userToken = api.getParameterByName("share_token");
		}
        if (userToken) {
			let positions = [];
			if (window.typeMeasurement === "marker") {
				let position = window.measurementMarker._position._value;
				positions.push(position.x);
				positions.push(position.y);
				positions.push(position.z);
			}
			else {
				for (let i = 0; i < window.measurementPoints.length; i ++) {
					let position = window.measurementPoints[i]._position._value;
					positions.push(position.x);
					positions.push(position.y);
					positions.push(position.z);
				}
			}
            let response = await api.createAnnotation({token: userToken, 
				project_id: props.selectedProject.project.id,
				dataset_id: -1,
				name: name, 
				description: description, type: window.typeMeasurement, 
				positions: JSON.stringify(positions),
				annotation_type: annotationType,
				severity_level: severitylevel.current,
				annotation_status: annotationStatus,
			});

            if (response.data && !response.data.error) {
                processAnnotation(response.data);
            }
            else {
                redirectToLogin();
            }
        }
        else {
            redirectToLogin();
        }
	}

	function manageImage() {
		if (api.isSharing()) {
			props.history.push("/image/viewer?project_id=" + showingImageFile.project_id 
			+ "&dataset_id=" + showingImageFile.dataset_id 
			+ "&file_id=" + showingImageFile.id + "&share_token=" + api.getParameterByName("share_token"));
		}
		else {
			props.history.push("/image/viewer?project_id=" + showingImageFile.project_id 
			+ "&dataset_id=" + showingImageFile.dataset_id 
			+ "&file_id=" + showingImageFile.id);
		}
		
	}

	function getImageView() {
		return (
			<img src={mapUtils.getImagePathLink(showingImageFile.dataset, showingImageFile.name, false, showingImageFile.width > 0, mapUtils.defaultImageSize)} width="100%"/>
		);
	}

	function hideImageView() {
		setShowingImageFile(null);
	}

	function selectAnnotationType(newValue, actionMeta) {
		if (newValue) {
			if (newValue.value) {
				setAnnotationType(newValue.value);
			}
		}
	}

	function selectAnnotationStatus(newValue, actionMeta) {
		if (newValue) {
			if (newValue.value) {
				setAnnotationStatus(newValue.value);
			}
		}
	}

	function handleChangeSeverityLevel(event) {
		setSeverityLevel(parseInt(event.target.value));
	}

	function changeFullScreen() {
		if (
			document.fullscreenElement ||
			document.webkitFullscreenElement ||
			document.mozFullScreenElement ||
			document.msFullscreenElement
		) {
			if (document.exitFullscreen) {
				document.exitFullscreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitExitFullscreen) {
				document.webkitExitFullscreen();
			} else if (document.msExitFullscreen) {
				document.msExitFullscreen();
			}
			setFullScreen(false);
		} else {
			let element = document.getElementsByClassName('viewer-cesium')[0];
			if (element.requestFullscreen) {
				element.requestFullscreen();
			} else if (element.mozRequestFullScreen) {
				element.mozRequestFullScreen();
			} else if (element.webkitRequestFullscreen) {
				element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
			} else if (element.msRequestFullscreen) {
				element.msRequestFullscreen();
			}
			setFullScreen(true);
		}
	}

	function isFullScreen() {
		return document.fullscreenElement ||
		document.webkitFullscreenElement ||
		document.mozFullScreenElement ||
		document.msFullscreenElement;
	}

	function visibleMenu() {
		setShowingAnnotationMenu(!isShowingAnnotationMenu);
	}

	function container() {
		// Use the fullscreen element if in fullscreen mode, otherwise just the document's body
		return document.fullscreenElement ?? document.body;
	}

	return (
		<main className={clsx(classes.view, {
			[classes.viewShift]: props.drawerVisibility,
		})}>
			<div className="viewer viewer-cesium">
				<div className="viewer-cesium-content-wrapper" id="cesium-wrapper"></div>
				<Toolbar
					zoomIn={_zoomInButtonClicked}
					zoomOut={_zoomOutButtonClicked}
					// reCenter={_reCenterButtonClicked}
				/>
				
				<div className="location-label-style" id="location_label">
					<div id="gis_location" className="gis-location-style"></div>
					<div id="gis_elevation" className="gis-elevation-style"></div>
				</div>
				<div className="annotation_toolbar">
					<div className="annotation_menu_icon can_hover_icon" title="FullScreen" onClick={changeFullScreen}>
						{fullscreen? <div className='icon fullscreen_exit'></div> : <div className='icon fullscreen_enter'></div>}
					</div>
					{api.canEditItem(props.userInfo)?
					<div className="annotation_menu_icon can_hover_icon" title="Visible Menu" onClick={visibleMenu}>
						{isShowingAnnotationMenu? <div className='icon viewmode_icon'></div> : <div className='icon view_mode_invisible_icon'></div>}
					</div>
					:null}
				</div>
				{api.canEditItem(props.userInfo) && isShowingAnnotationMenu?
				<div id='bottom_project_menu'>
					<div className="select_left_icon can_hover_icon" onClick={startAddMarker}><div className='icon marker_icon'></div></div>
					<div className="select_left_icon can_hover_icon" onClick={startAddDistance}><div className='icon measurement_line_icon'></div></div>
					<div className="select_left_icon can_hover_icon" onClick={startAddPolyline}><div className='icon measurement_polyline_icon'></div></div>
					<div className='draw_select_menu'>
						<Select
							isMulti={false}
							onChange={selectAnnotationType}
							styles={selectStyle}
							options={annotationTypeList}
							isSearchable={false}
							menuPlacement="top"
							value = {
								annotationTypeList.filter(function (option) {
									return option.value === annotationType;
								})
							}
						></Select>
					</div>
					<div className='severity_selection_type'>
						<div className='selection_list'>
							<div className='severity_level_1'>
								<Radio
									checked={severitylevel.current === 1}
									onChange={handleChangeSeverityLevel}
									value="1"
									name="radio-buttons"
									sx={{
										color: "grey",
										'&.Mui-checked': {
											color: "black",
										},
									}}
								/>
							</div>
							<div className='severity_level_2'>
								<Radio
									checked={severitylevel.current === 2}
									onChange={handleChangeSeverityLevel}
									value="2"
									name="radio-buttons"
									sx={{
										color: "grey",
										'&.Mui-checked': {
											color: "black",
										},
									}}
								/>
							</div>
							<div className='severity_level_3'>
								<Radio
									checked={severitylevel.current === 3}
									onChange={handleChangeSeverityLevel}
									value="3"
									name="radio-buttons"
									sx={{
										color: "grey",
										'&.Mui-checked': {
											color: "black",
										},
									}}
								/>
							</div>
							<div className='severity_level_4'>
								<Radio
									checked={severitylevel.current === 4}
									onChange={handleChangeSeverityLevel}
									value="4"
									name="radio-buttons"
									sx={{
										color: "grey",
										'&.Mui-checked': {
											color: "black",
										},
									}}
								/>
							</div>
						</div>
					</div>
				</div>
				:null}
				<div>
					{ isShowingContextMenu && api.canEditItem(props.userInfo)?
						<div
							className="map-context-menu"
							style={{
								marginLeft: anchorPosition.x + 1,
								marginTop: anchorPosition.y + 1,
							}}
						>
							<div className="context_menu_icon can_hover_icon" onClick={startAddMarker}><div className='icon marker_icon'></div></div>
							<div className="context_menu_icon can_hover_icon" onClick={startAddDistance}><div className='icon measurement_line_icon'></div></div>
							<div className="context_menu_icon can_hover_icon" onClick={startAddPolyline}><div className='icon measurement_polyline_icon'></div></div>
						</div>
					:null}
				</div>
			</div>
			<Modal show={isCreatingLidarAnnotation} animation={false} className="modal-annotation-create" container={container()}>
                <Modal.Header>
					<div className='creating_component_dialog'>
						<Modal.Title>Create Annotation</Modal.Title>
						<div className='close_button' onClick={hideAnnoationDialog}>
							<img src="/close.png" alt="close" style={{height: "100%"}}/>
						</div>
					</div>
                </Modal.Header>
                <Modal.Body>
                    <div className='project-name-box'>
                        <p className="project-name-title">Annotation Title</p>
                        <input type="text" className="project-name-input" 
                        value={creatingAnnotationName} 
                        onChange={onChangeAnnotationName}></input>
                    </div>
                    <div className='project-name-box'>
                        <p className="project-name-title">Annotation Comment</p>
                        <textarea className="project-name-input" 
                        value={creatingAnnotationDescription} 
                        onChange={onChangeAnnotationDescription}></textarea>
                    </div>
                </Modal.Body>
                <Modal.Footer>
					<div className='annotation_info_part'>
                        <div className='annotation_edit_info_part'>
                            <div className='annotation_selection_type'>
                                <div className='annotation_info_label'>Annotation ID:</div>
                                <div className='annotation_info_value'></div>
                            </div>
                            <div className='annotation_selection_type'>
                                <div className='annotation_info_label'>Annotation By:</div>
                                <div className='annotation_info_value'>{props.userInfo.email}</div>
                            </div>
                        </div>
                        <div className='annotation_edit_info_part'>
                            <div className='annotation_selection_type'>
                                <div className='selection_label'>Annotation Type</div>
                                <div className='selection_list'>
                                    <Select
                                        isMulti={false}
                                        onChange={selectAnnotationType}
                                        styles={selectStyle}
                                        options={annotationTypeList}
                                        isSearchable={false}
                                        value = {
                                            annotationTypeList.filter(function (option) {
                                                return option.value === annotationType;
                                            })
                                        }
                                    ></Select>
                                </div>
                            </div>
                            <div className='annotation_selection_type'>
                                <div className='selection_label'>Annotation Status</div>
                                <div className='selection_list'>
                                    <Select
                                        isMulti={false}
                                        onChange={selectAnnotationStatus}
                                        styles={selectStyle}
                                        options={annotationStatusList}
                                        isSearchable={false}
                                        value = {
                                            annotationStatusList.filter(function (option) {
                                                return option.value === annotationStatus;
                                            })
                                        }
                                    ></Select>
                                </div>
                            </div>
                            <div className='severity_selection_type'>
                                <div className='selection_label'>Severity Level 1-4</div>
                                <div className='selection_list'>
                                    <div className='severity_level_1'>
                                        <Radio
                                            checked={severitylevel.current === 1}
                                            onChange={handleChangeSeverityLevel}
                                            value="1"
                                            name="radio-buttons"
                                            sx={{
                                                color: "grey",
                                                '&.Mui-checked': {
                                                    color: "black",
                                                },
                                            }}
                                        />
                                    </div>
                                    <div className='severity_level_2'>
                                        <Radio
                                            checked={severitylevel.current === 2}
                                            onChange={handleChangeSeverityLevel}
                                            value="2"
                                            name="radio-buttons"
                                            sx={{
                                                color: "grey",
                                                '&.Mui-checked': {
                                                    color: "black",
                                                },
                                            }}
                                        />
                                    </div>
                                    <div className='severity_level_3'>
                                        <Radio
                                            checked={severitylevel.current === 3}
                                            onChange={handleChangeSeverityLevel}
                                            value="3"
                                            name="radio-buttons"
                                            sx={{
                                                color: "grey",
                                                '&.Mui-checked': {
                                                    color: "black",
                                                },
                                            }}
                                        />
                                    </div>
                                    <div className='severity_level_4'>
                                        <Radio
                                            checked={severitylevel.current === 4}
                                            onChange={handleChangeSeverityLevel}
                                            value="4"
                                            name="radio-buttons"
                                            sx={{
                                                color: "grey",
                                                '&.Mui-checked': {
                                                    color: "black",
                                                },
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
					<Button variant="contained"
						color="primary" onClick={createAnnotation} className={classes.annotation_button}>
						Create
					</Button>
                </Modal.Footer>
            </Modal>
			{isShowingImageView?
				<Modal show={isShowingImageView} animation={false} className="modal-image-viewer" container={container()}>
					<Modal.Header>
						<div className='creating_component_dialog'>
							<Modal.Title>{showingImageFile.name}</Modal.Title>
							<div className='close_button' onClick={hideImageView}>
								<img src="/close.png" alt="close" style={{height: "100%"}}/>
							</div>
						</div>
					</Modal.Header>
					<Modal.Body>
						<div className='image-view'>
							{getImageView()}
						</div>
					</Modal.Body>
					<Modal.Footer>
					<Button variant="contained"
						color="secondary" onClick={manageImage} className={classes.button}>
						Image Inspector
					</Button>
					</Modal.Footer>
				</Modal>
			:null}
		</main >
	);
}

const mapStateToProps = state => ({
	drawerVisibility: state.global.drawerVisibility,
	mapType: state.map.mapType,
	selectedProject: state.project.selectedProject,
	allAssets: state.project.allAssets,
	labelProperty: state.project.labelProperty,
	datasetList : state.project.datasetList,
	userInfo: state.global.userInfo
});

MapView.propTypes = {
	drawerVisibility: PropTypes.bool.isRequired,
	mapType: PropTypes.number.isRequired,
}

export default compose(
	withRouter,
	connect(mapStateToProps, globalAction),
	connect(mapStateToProps, mapAction),
	connect(mapStateToProps, projectAction),
)(MapView);
export const initialState = {
  selectedProject: {
    project : {
      id : -1
    }
  },
  allAssets: [],
  allDatasets: [],
  analyticsInfo: undefined,
  isLatestAnalytics: false,
  labelProperty: "none",
  addingPosition: undefined,
  labelProperty: "none",
  sortType: "date",
  sortOrder: "asc",
  allProjects: [],
  datasetList : new Map(),
  classificationList : [
    { index : 0, visible: true, name: 'Not classified', color: "#bfbfbf" },
    { index : 1, visible: true, name: 'Unclassified', color: "#808080" },
    { index : 2, visible: true, name: 'Ground', color: "#833c0c" },
    { index : 3, visible: true, name: 'Low vegetation', color: "#92d050" },
    { index : 4, visible: true, name: 'Medium vegetation', color: "#00b050" },
    { index : 5, visible: true, name: 'High vegetation', color: "#375623" },
    { index : 6, visible: true, name: 'Building', color: "#203764" },
    { index : 7, visible: true, name: 'Low Noise', color: "#000000" },
    { index : 8, visible: true, name: 'Model Keypoint', color: "#5c1d1d" },
    { index : 9, visible: true, name: 'Water', color: "#00b0f0" },
    { index : 10, visible: true, name: 'Rail', color: "#7030a0" },
    { index : 11, visible: true, name: 'Road surface', color: "#595959" },
    { index : 12, visible: true, name: 'Overlap', color: "#f8cbad" },
    { index : 13, visible: true, name: 'Wire Shield/Neutral/Com', color: "#444444" },
    { index : 14, visible: true, name: 'Wire Conductors/Phases', color: "#ffffff" },
    { index : 15, visible: true, name: 'Transmission Tower', color: "#0070c0" },
    { index : 16, visible: true, name: 'Wire Insulators', color: "#262626" },
    { index : 17, visible: true, name: 'Bridge Deck', color: "#c9c9c9" },
    { index : 18, visible: true, name: 'High Noise', color: "#000000" },
    { index : 64, visible: true, name: 'Conductor Attachment Points', color: "#623c0d" },
    { index : 65, visible: true, name: 'Shield Attachment Points', color: "#e51893" },
    { index : 66, visible: true, name: 'Midspan Points', color: "#271054" },
    { index : 67, visible: true, name: 'Structure Top Points', color: "#c11818" },
    { index : 68, visible: true, name: 'Structure Bottom Points', color: "#381570" },
    { index : 70, visible: true, name: 'Guy Wire', color: "#002060" },
    { index : 75, visible: true, name: 'Substation', color: "#7030a0" },
    { index : 81, visible: true, name: 'Misc Temporary', color: "#000000" },
    { index : 82, visible: true, name: 'Misc Permanent', color: "#808080" },
    { index : 83, visible: true, name: 'Misc Fences', color: "#203764" },
  ]
};
import { initialState } from './selectors';
import {
  POLYGON_AREA, POLYGON_PERIMETER, POLYGON_MIN_ELEVATION, POLYGON_MAX_ELEVATION, POLYGON_ELEVATION_DIFFERENCE,
  POLYGON_BASE_SURFACE, POLYGON_CUT_POLYGON, POLYGON_CUT_ERROR, POLYGON_FILL_POLYGON, POLYGON_FILL_ERROR, POLYGON_DIFFERENCE, CALCULATE_POLYGON, SLOPE_POLYGON
} from './actions';

export default function polygon(state = initialState, action = {}) {
  const { type, payload } = action
  switch (type) {
    // Auth check
    case POLYGON_AREA:
      return {
        ...state,
        polygonArea: payload
      }
    case SLOPE_POLYGON:
      return {
        ...state,
        slope: payload
      }
    case POLYGON_PERIMETER:
      return {
        ...state,
        polygonPerimeter: payload
      }
    case POLYGON_MIN_ELEVATION:
      return {
        ...state,
        polygon_min_elevation: payload
      }
    case POLYGON_MAX_ELEVATION:
      return {
        ...state,
        polygon_max_elevation: payload
      }
    case POLYGON_ELEVATION_DIFFERENCE:
      return {
        ...state,
        polygon_elevation_difference: payload
      }
    case POLYGON_BASE_SURFACE:
      return {
        ...state,
        polygon_base_surface: payload
      }
    case POLYGON_CUT_POLYGON:
      return {
        ...state,
        polygon_cut_polygon: payload
      }
    case POLYGON_CUT_ERROR:
      return {
        ...state,
        polygon_cut_error: payload
      }
    case POLYGON_FILL_POLYGON:
      return {
        ...state,
        polygon_fill_polygon: payload
      }

    case POLYGON_FILL_ERROR:
      return {
        ...state,
        polygon_fill_error: payload
      }

    case POLYGON_DIFFERENCE:
      return {
        ...state,
        polygon_difference: payload
      }

    case CALCULATE_POLYGON:
      return {
        ...state,
        calculatePolygonValue: payload
      }
    default:
      return state;
  }
}

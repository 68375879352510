import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import { globalAction } from '../../store/actions';
import { projectAction } from '../../store/actions';
import Header from '../../components/Header';
import SideBar from './Sidebar';

import './style.scss'
import MapView from '../../components/MapView';
import { DRAWING_NONE } from '../../static/constants';
import { isExpireTokenLocal } from '../../utils/utilFunctions';
import * as api from '../../utils/api';
import { toast } from 'react-toastify';
import {Modal} from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
	button: {
        fontSize: 15,
        fontWeight: 400,
        marginRight: 20
    },
}));

function MapLayout(props) {
	const classes = useStyles();

	const [isShowingError, setShowError] = React.useState(false);
    const [errorText, setErrorText] = React.useState("");

	let projectID = sessionStorage.getItem("selected_project_id");

	if (api.getParameterByName("project_id") && api.getParameterByName("project_id") !== "") {
		projectID = api.getParameterByName("project_id");
	}

	if (projectID) {
		api.setParameter("project_id", projectID);
	}

	async function getAnalyticsInfo() {
		let userToken = localStorage.getItem("userToken");
        if (userToken) {
            let response = await api.getAnalytics({token: userToken});

            if (response.data && !response.data.error) {
				props.setLatestAnalytics(true);
                props.setAnalyticsInfo(response.data);
            }
            else {
                redirectToLogin();
            }
        }
        else {
            redirectToLogin();
        }
	}

	async function getLastAnalytics() {
		let userToken = localStorage.getItem("userToken");
        if (userToken) {
            let response = await api.getLastAnalytics({token: userToken});

            if (response.data && !response.data.error) {
				if (response.data.analytics && response.data.analytics.length > 0) {
					props.setAnalyticsInfo(response.data.analytics[0]);
				}
            }
            else {
                redirectToLogin();
            }
        }
        else {
            redirectToLogin();
        }
	}

	async function getAllProjects() {
		let userToken = localStorage.getItem("userToken");
        if (userToken) {
            let response = await api.getProjects({token: userToken});

            if (response.data && !response.data.error) {
                props.setAllProjects(response.data);
            }
            else {
                redirectToLogin();
            }
        }
        else {
            redirectToLogin();
        }
	}

	async function getAllDataset() {
		let userToken = localStorage.getItem("userToken");
        if (userToken) {
            let response = await api.getDatasetList({token: userToken});
            if (response.data && !response.data.error) {
                props.setAllDatasets(response.data);
            }
            else {
                redirectToLogin();
            }
        }
        else {
            redirectToLogin();
        }
	}

	useEffect(() => {
		if (api.isSharing()) {
			let share_token = api.getParameterByName("share_token");
			projectID = api.getParameterByName("project_id");
			getShareLink(share_token, projectID);
		}
		else {
			getAllProjects();
			getAllDataset();
			getLastAnalytics();
			getAnalyticsInfo();
			getAssets();

			if (projectID) {
				getProjectItem(projectID);
			}
			else {
				props.selectProject({
					project : {
						id : -1
					}
				});
			}
		}
	}, []);

	if (api.isSharing()) {
		// Sharing Part
	}
	else if (isExpireTokenLocal()) {
		return <Redirect to="/" />
	}

	async function getShareLink(token, project_id) {
		let response = await api.getShareLink({project_id :project_id, token: token});
			if (response.data && !response.data.error) {
				if (response.data.password !== "" && !api.compareShareInfo(token, project_id, response.data.password)) {
					window.hasSharePassword = true;
					props.history.push("/" + props.location.search);
				}
				else {
					window.hasSharePassword = false;
					getProjectItem(project_id, token);
				}
			}
			else {
				if (response.data) {
					setErrorText(response.data.error);
					setShowError(true);
				}
				else {
					redirectToLogin();
				}
				
			}
	}

	async function getAssets() {
		let userToken = localStorage.getItem("userToken");
		if (userToken) {
			let response = await api.getAssetsByID({token: userToken});
			if (response.data && !response.data.error) {
				props.setAllAssets(response.data);
			}
			else {
				redirectToLogin();
			}
		}
		else {
			redirectToLogin();
		}
	}

	async function getProjectItem(projectID, share_token) {
		let userToken = localStorage.getItem("userToken");
		if (share_token && share_token !== "") {
			userToken = share_token;
		}
		if (userToken) {
			let response = await api.getProjectByID({id :projectID, token: userToken});
			if (response.data && !response.data.error) {
				props.selectProject(response.data);
			}
			else {
				redirectToLogin();
			}
		}
		else {
			redirectToLogin();
		}
	}

	function redirectToLogin() {
		props.history.push("/");
	}

	function hideErrorDialog() {
		setErrorText("");
		setShowError(false);
		redirectToLogin();
	}

	return (
		<div>
			<Header
				hasSearch={true}
			/>
			<SideBar
			>
			</SideBar>
			<MapView
				mode={DRAWING_NONE}
			/>
			<Modal show={isShowingError} animation={false}>
                <Modal.Header>
					<div className='delete_dialog_title'>
						<Modal.Title>Error</Modal.Title>
						<div className='close_button' onClick={hideErrorDialog}>
							<img src="/close.png" alt="close" style={{height: "100%"}}/>
						</div>
					</div>
                </Modal.Header>
                <Modal.Body>
                    <div className='delete_dialog_message'>
						{errorText}
                    </div>
                </Modal.Body>
				<Modal.Footer>
					<Button variant="contained"
						color="secondary" onClick={hideErrorDialog} className={classes.button}>
						Close
					</Button>
                </Modal.Footer>
            </Modal>
		</div>
	);
}

const mapStateToProps = state => ({
});

MapLayout.propTypes = {
}

export default compose(
	withRouter,
	connect(mapStateToProps, globalAction),
	connect(mapStateToProps, projectAction),
)(MapLayout);